import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Box } from "@material-ui/core";
import { formatPriceWithCountry } from "../../util/util-ems";
import { AuthContext } from "../../../../core/providers/AuthContext";
import ProductImage from "../ProductImage";

const useStyles = makeStyles((theme) => ({
  productImage: {
    width: 80,
    height: 80,
    objectFit: "cover",
  },
  productInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  quantityButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 60,
    padding: theme.spacing(0.5),
    backgroundColor: `${theme.palette.primary.main}DD`,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    marginLeft: "8px",
  },
  actionButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  buyAgainButton: {
    marginBottom: theme.spacing(1),
  },
  viewItemButton: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

const HistoryItemContainerDetails = (props) => {
  const { title, purchasePrice, purchaseQuantity } = props;
  const classes = useStyles();
  const { auth } = useContext(AuthContext);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={2}>
        <ProductImage {...props} />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.productInfo}>
        <Typography variant="h6">{title}</Typography>
        <Box display="flex" alignItems="center" mt={1}>
          <Typography variant="subtitle1">
            {formatPriceWithCountry({
              price: purchasePrice,
              country: auth?.emsCountry,
            })}
          </Typography>
          <div className={classes.quantityButton}>x{purchaseQuantity}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HistoryItemContainerDetails;
