import React from "react";
import {
  commonIconStyles,
  useStyles,
} from "../styles/LoginProfileChooserStyles";
import Grid from "@material-ui/core/Grid";
import { Box, Icon, Typography } from "@material-ui/core";

const PROFILES_MOCK = [
  {
    userId: "FEDERICO.FERNANDEZ",
    mail: "ffernandez@dsuiteapp.onmicrosoft.com",
    profileCode: "MKT-LID",
    profileDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tincidunt non risus eu aliquam. Mauris sit amet tempor dui. Praesent vehicula aliquam est nec aliquam. Mauris sit amet est gravida, facilisis purus non, aliquam lectus. Nulla turpis duis.",
    token: "",
  },
  {
    userId: "FFERNANDEZ",
    mail: "ffernandez@dsuiteapp.onmicrosoft.com",
    profileCode: "MKT-LUJO-CERAN",
    profileDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a lectus ut risus sagittis gravida sed a turpis. Donec tristique, magna sed consectetur ullamcorper, ante nunc posuere urna, ut egestas neque elit et turpis. Phasellus faucibus diam biam.",
    token: "",
  },
];

export default function LoginProfileChooser() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.contentBox}>
        <Box className={classes.titleBox}>
          <Icon
            className="fas fa-users"
            style={commonIconStyles}
            fontSize="large"
          />
          <Typography variant="h3" className={classes.title}>
            Please choose one of your profiles to continue using the platform
          </Typography>
        </Box>

        <Grid container spacing={2} className={classes.gridContainer}>
          {PROFILES_MOCK.map((profile, index) => (
            <Grid
              item
              key={profile.userId}
              xs={12}
              sm={6}
              md={4}
              lg={6}
              className={classes.gridItem}
            >
              <Box className={classes.profileCard}>
                <Box className={classes.infoBox}>
                  <Icon
                    className="fas fa-tag"
                    style={commonIconStyles}
                    fontSize="medium"
                  />
                  <Typography variant="h4">{profile.profileCode}</Typography>
                </Box>
                <Box className={classes.infoBox}>
                  <Icon
                    className="fas fa-user-tag"
                    style={commonIconStyles}
                    fontSize="small"
                  />
                  <Typography variant="subtitle1">{profile.userId}</Typography>
                </Box>
                <Typography variant="body1" className={classes.descriptionText}>
                  {profile.profileDesc}
                </Typography>
                <Typography variant="h6" className={classes.profileIndexText}>{`#${index + 1}`}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
