import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  AppBar,
  Button,
  Hidden,
  Icon,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  HOME_TAB,
  IS_ENV_SELECTOR_ENABLED,
} from "../../../../util/Constants";
import { appBarHeight } from "../../LayoutStylesGlobals";
import CompanyAndUser from "./info/CompanyAndUser";
import DropDownSettings from "./dropdown/DropDownSettings";
import EnvironmentButtons from "../envButtons/EnvironmentButtons";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { ThemeContext } from "../../../../core/providers/ThemeContext";
import AppBarSearch from "./search/AppBarSearch";
import { LayoutContext } from "../../../../core/providers/LayoutContext";
import { DrawerContext } from "../../../../core/providers/DrawerContext";
import UsernameAndClock from "./info/UsernameAndClock";
import ToggleMenuButton from "../envButtons/ToggleMenuButton";
import { includes, isEmpty, isNil, isString, toLower, toUpper } from "lodash";
import useEnvironmentStore, {
  DEFAULT_LABEL,
} from "../../../../core/stores/EnvironmentStore";
import { Skeleton } from "@material-ui/lab";
import { useAccessesStore } from "../../../../core/stores/AccessesStore";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { isChristmasMonth } from "../../../../util/UtilChirimbolos";
// import SolutionsGrid from "./solutionsGrid/SolutionsGrid";

const headerChristmasImgBase = {
 // position: "absolute",
 // top: "0%",
  pointerEvents: "none",
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: "100%",
    height: `${appBarHeight * 2}px`,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: theme.palette.header.backgroundColor,
    //border: `2px solid transparent`,
    borderBottom: `1px solid ${theme.palette.content.mainColorContrastText}AA`,
    zIndex: 3,
    padding: 5,
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      height: `${appBarHeight}px`,
    },
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${appBarHeight - 3}px)`,
    },
  },
  toolbar: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    padding: "0px 10px 0px 3px",
    flexDirection: "column",
    [theme.breakpoints.up(BREAKPOINT_SM)]: {
      flexDirection: "row",
    },
  },
  toolbarLeftSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down(BREAKPOINT_MD)]: {
      width: "100%",
    },
    [theme.breakpoints.down(BREAKPOINT_SM)]: {
      minHeight: "50%",
    },
  },
  toolbarLeftSideContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toolbarRightSide: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "-1em",
  },
  logoFailedTypo: {
    color: theme.palette.content.mainColorContrastText,
    cursor: "pointer",
  },
  logoUrlImg: {
    width: 125,
    cursor: "pointer",
    marginLeft: 10,
  },
  envHeaderButton: {
    backgroundColor: ({ selectedEnvInfo, isEnvEnabled }) =>
      isEnvEnabled === true && selectedEnvInfo !== null
        ? selectedEnvInfo.bgBars
        : "transparent",
    padding: ({ selectedEnvInfo, isEnvEnabled }) =>
      isEnvEnabled === true && selectedEnvInfo !== null ? 10 : 0,
    borderRadius: ({ selectedEnvInfo, isEnvEnabled }) =>
      isEnvEnabled === true && selectedEnvInfo !== null ? 10 : 0,
    color: "#FFF",
    fontSize: 14,
    "&:hover": {
      backgroundColor: ({ selectedEnvInfo, isEnvEnabled }) =>
        isEnvEnabled === true && selectedEnvInfo !== null
          ? selectedEnvInfo.bgBars
          : "transparent",
      transform: "scale(1.03)",
    },
  },
  solutionLogoImg: {
    maxHeight: "35px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  solutionLogoImgHorizontal: {
    maxHeight: "45px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  companyAndUserContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 10px",
    },
  },
  headerChristmasImgHome: {
    ...headerChristmasImgBase,
    height: appBarHeight,
    right: "43%",
  },
  headerChristmasImg: {
    ...headerChristmasImgBase,
    height: appBarHeight,
    right: "43%",
  },
}));

const MAP_SOLUTIONS_HEADER_IMG = {
  "apollo/": "APOLLO",
  "/consultas/v2/Apo": "APOLLO",
  "avis/": "AVIS",
  "/consultas/v2/Avi": "AVIS",
  "friday/": "FRIDAY",
  "/consultas/v2/Fri": "FRIDAY",
  "oneprice/": "ONEPRICE",
  "/consultas/v2/One": "ONEPRICE",
  "fenix/": "FENIX",
  "/consultas/v2/Fen": "FENIX",
  "/plv/Uru": "TIMBO",
  "/plv/Tim": "TIMBO",
  "/consultas/v2/Tim": "TIMBO",
};

const MAP_SPECIAL_LOGO_COMPANY = ["BERKES"];

function getCorrectHeaderImgBySolution({ locationPathName, headerImgContext }) {
  let result = headerImgContext;
  for (let key in MAP_SOLUTIONS_HEADER_IMG) {
    if (
      !isNil(locationPathName) &&
      !isEmpty(locationPathName) &&
      isString(locationPathName) &&
      includes(toLower(locationPathName), toLower(key))
    ) {
      result = MAP_SOLUTIONS_HEADER_IMG[key];
      break;
    }
  }
  return result;
}

const IS_CHRISTMAS_MONTH = isChristmasMonth();

export default function CustomAppBar(props) {
  const {
    handleComboCompany,
    handleDrawerOpenState,
    openDrawer,
    handleTabClick,
    menuItems,
    pushPage,
    setOpenLogoutConfirmation,
    handleProcessClick,
  } = props;

  const { t } = useTranslation();
  const { auth, logout } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const { canGoBack, activeTab } = useContext(LayoutContext);
  const { headerImg: headerImgContext, changeHeaderImg } =
    useContext(DrawerContext);
  const history = useHistory();
  const location = useLocation();

  const [logoFailed, setLogoFailed] = useState(false);

  const REQUEST_HEADERS = useRequestHeaders();

  const selectedEnvInfo = useEnvironmentStore((state) => state.selectedEnvInfo);

  const { callServerToGetCustomHeaderLogo } = useAccessesStore((state) => ({
    callServerToGetCustomHeaderLogo: state.callServerToGetCustomHeaderLogo,
  }));

  const classes = useStyles({
    openDrawer,
    selectedEnvInfo,
    isEnvEnabled: IS_ENV_SELECTOR_ENABLED === true,
  });

  const specialLogoByCompany = useMemo(() => {
    return includes(MAP_SPECIAL_LOGO_COMPANY, auth?.company?.codCompany);
  }, [auth]);

  const [logoUrl, setLogoUrl] = useState("");
  useEffect(() => {
    const fetchLogoUrl = async () => {
      const defaultLogo =
        auth && auth.company && auth.company.codCompany && theme
          ? process.env.REACT_APP_ENV
            ? `${process.env.PUBLIC_URL}/assets/img/${auth.company.codCompany}_${theme}_${process.env.REACT_APP_ENV}.png`
            : `${process.env.PUBLIC_URL}/assets/img/${auth.company.codCompany}_${theme}.png`
          : null;

      if (specialLogoByCompany) {
        try {
          const company = auth?.company?.codCompany;
          const responseEndpoint = await callServerToGetCustomHeaderLogo({
            REQUEST_HEADERS,
            logout,
            company,
          });
          setLogoUrl(
            `${process.env.PUBLIC_URL}/assets/img/header-logo/${toUpper(
              company
            )}/${responseEndpoint}_${theme}.png`
          );
        } catch (error) {
          setLogoUrl(defaultLogo);
        }
      } else {
        setLogoUrl(defaultLogo);
      }
    };

    fetchLogoUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, theme, specialLogoByCompany]);

  const headerImg = useMemo(() => {
    return getCorrectHeaderImgBySolution({
      locationPathName: location?.pathname,
      headerImgContext,
    });
  }, [headerImgContext, location]);

  return (
    <AppBar position="absolute" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {/* LEFT SIDE */}
        <div className={classes.toolbarLeftSide}>
          <div className={classes.toolbarLeftSideContent}>
            <Hidden mdUp>
              <ToggleMenuButton
                handleDrawerOpenState={handleDrawerOpenState}
                openDrawer={openDrawer}
              />
            </Hidden>

            {isNil(logoUrl) && specialLogoByCompany && (
              <Skeleton variant="rect" width={150} height={40} />
            )}

            {logoUrl && logoFailed && (
              <Typography
                variant="h4"
                className={classes.logoFailedTypo}
                onClick={() => handleTabClick(HOME_TAB, false)}
              >
                {auth.company.name}
              </Typography>
            )}

            {/* COMPANY LOGO */}
            {logoUrl && IS_ENV_SELECTOR_ENABLED !== true && (
              <img
                src={logoUrl}
                alt="company-logo"
                onError={() => setLogoFailed(true)}
                onLoad={() => setLogoFailed(false)}
                onClick={() => handleTabClick(HOME_TAB, false)}
                className={classes.logoUrlImg}
              />
            )}

            {logoUrl && IS_ENV_SELECTOR_ENABLED === true && (
              <Button
                className={classes.envHeaderButton}
                onClick={() => handleTabClick(HOME_TAB, false)}
              >
                {selectedEnvInfo?.label || DEFAULT_LABEL}
              </Button>
            )}

            <Hidden smDown>
              <AppBarSearch menuItems={menuItems} pushPage={pushPage} />
            </Hidden>
          </div>

          {/* CAN GO BACK ARROW */}
          {canGoBack === true && (
            <Tooltip title={t("FORM_BACK")}>
              <IconButton
                style={{ marginRight: 5 }}
                onClick={() => history.goBack()}
              >
                <Icon
                  className="fas fa-arrow-left"
                  style={{ fontSize: "16px" }}
                />
              </IconButton>
            </Tooltip>
          )}

          {/* COMPANY + USER  MOBILE */}
          <Hidden smUp>
            <div className={classes.companyAndUserContainer}>
              {/* CONFIGURATION DROPDOWN */}
              <DropDownSettings
                setOpenLogoutConfirmation={setOpenLogoutConfirmation}
                handleComboCompany={handleComboCompany}
              />
              <CompanyAndUser
                setOpenLogoutConfirmation={setOpenLogoutConfirmation}
              />
              {/* <Hidden xsDown>
                 <LogoutButton
                  setOpenLogoutConfirmation={setOpenLogoutConfirmation}
                /> 
              </Hidden> */}
            </div>
          </Hidden>
        </div>

        {/* CENTER SIDE */}
        <Hidden only={["xs", "md", "lg", "xl"]}>
          <EnvironmentButtons
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
        </Hidden>
        {IS_CHRISTMAS_MONTH && (
          <Hidden smDown>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/holidays/christmas-header-${theme}.webp`}
              className={
                location?.pathname === "/"
                  ? classes.headerChristmasImgHome
                  : classes.headerChristmasImg
              }
              alt="christmas-wreath-img"
            />
          </Hidden>
        )}

        {/* RIGHT SIDE */}
        <div className={classes.toolbarRightSide}>
          {/* SOLUTION LOGO */}
          {headerImg && headerImg !== "" && (
            <img
              className={classes.solutionLogoImgHorizontal}
              src={
                process.env.REACT_APP_ENV
                  ? `${process.env.PUBLIC_URL}/assets/img/${headerImg}_${theme}_${process.env.REACT_APP_ENV}.png`
                  : `${process.env.PUBLIC_URL}/assets/img/${headerImg}_${theme}.png`
              }
              alt="solution-logo"
              onError={() => {
                changeHeaderImg(null);
              }}
            />
          )}

          {/* COMPANY + USER DESKTOP*/}
          <Hidden xsDown>
            <div className={classes.companyAndUserContainer}>
              <UsernameAndClock />
              {/* <IconButton>
                <Icon className="fas fa-question-circle" style={iconAuto} />
              </IconButton> */}
              <DropDownSettings
                handleComboCompany={handleComboCompany}
                setOpenLogoutConfirmation={setOpenLogoutConfirmation}
              />
              {/* <SolutionsGrid /> */}
              <CompanyAndUser
                setOpenLogoutConfirmation={setOpenLogoutConfirmation}
              />
              {/* CONFIGURATION DROPDOWN */}

              {/* <LogoutButton
                setOpenLogoutConfirmation={setOpenLogoutConfirmation}
              /> */}
            </div>
          </Hidden>
        </div>

        <Hidden smUp>
          <EnvironmentButtons
            isMobile={true}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            handleProcessClick={handleProcessClick}
          />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
