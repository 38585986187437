import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Checkbox, Box } from "@material-ui/core";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import useEmployeeStoreState from "../store/EmployeeStoreState";
import useFavorites from "../store/hooks/useFavorites";
import ProductActions from "./ProductActions";
import ProductImage from "./ProductImage";
import ProductPriceAndStock from "./ProductPriceAndStock";
import { isEmpty, isNil } from "lodash";
import useInfo from "../store/hooks/useInfo";
import FilterAdminBadge from "./filters/FilterAdminBadge";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    position: "relative",
    borderRadius: 3,
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(1),
    },
  },
  itemGrid: {
    alignItems: "center",
  },
  favButton: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 4,
  },
  bold: {
    fontWeight: 700,
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
  },
  minQuantity: {
    paddingTop: 4,
    paddingBottom: 2,
  },
  itemTitle: {
    fontSize: 18,
    fontWeight: 700,
  },
  click: {
    cursor: "pointer",
  },
  infoItemBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
  },
}));

const ListItem = ({ item, onQuickView }) => {
  const {
    title,
    firmDescription,
    minQuantity,
    imageUrl,
    ean,
    salesOrganizationCode,
    distributionChannelCode,
    firm,
    sectorCode,
    sectorMixCode,
    catalogCode,
  } = item;
  const { t } = useTranslation();
  const classes = useStyles();
  const { favorites, addFavorite, removeFavorite, loadingFavorites } =
    useFavorites();

  const loadingProducts = useEmployeeStoreState(
    (state) => state.loadingProducts
  );

  const { isEmsAdmin } = useInfo();

  const isProductFavorite = useMemo(() => {
    return (
      !isNil(favorites) &&
      !isEmpty(favorites) &&
      favorites.some((fav) => fav?.product?.ean === ean)
    );
  }, [favorites, ean]);

  const addOrRemoveFromFavorites = (event) => {
    if (event.target.checked) {
      addFavorite(item);
    } else {
      removeFavorite(item);
    }
  };

  return (
    <>
      {loadingProducts ? (
        <Skeleton
          variant="rect"
          width={"100%"}
          height={150}
          style={{ marginBottom: 20 }}
        />
      ) : (
        <Paper className={classes.item}>
          <Grid container spacing={1} className={classes.itemGrid}>
            <Grid
              item
              xs={12}
              md={2}
              onClick={() => onQuickView(item)}
              className={classes.click}
            >
              <ProductImage imageUrl={imageUrl} title={title} firm={firm} />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              onClick={() => onQuickView(item)}
              className={classes.click}
            >
              <Typography className={classes.itemTitle}>{title}</Typography>
              <Typography className={classes.description}>
                {firmDescription}
              </Typography>
              {isEmsAdmin && (
                <Typography variant="body2" className={classes.minQuantity}>
                  {t("EMPLOYEE_STORE.PRODUCT_UME", {
                    minQuantity,
                  })}
                </Typography>
              )}

              {isEmsAdmin ? (
                <Box className={classes.infoItemBox}>
                  <Typography variant="subtitle2">
                    {`EAN: ${ean} | Org: ${salesOrganizationCode} | Canal: ${distributionChannelCode} | Sector: ${sectorCode} | Sector Mix: ${sectorMixCode} | Catalogo: ${catalogCode}`}
                  </Typography>
                  <FilterAdminBadge showOnlyAdmin={true} />
                </Box>
              ) : (
                <Box className={classes.infoItemBox}>
                  <Typography variant="subtitle2">{`EAN: ${ean} | ${t(
                    "EMPLOYEE_STORE.PRODUCT_UME",
                    {
                      minQuantity,
                    }
                  )}`}</Typography>
                </Box>
              )}

              <ProductPriceAndStock {...item} />
            </Grid>
            <Grid item xs={12} md={2}>
              <ProductActions item={item} />
            </Grid>
            <Checkbox
              className={classes.favButton}
              icon={
                <FavoriteBorder
                  color={loadingFavorites ? "disabled" : "action"}
                />
              }
              checkedIcon={
                <Favorite color={loadingFavorites ? "disabled" : "error"} />
              }
              name="fav"
              checked={isProductFavorite}
              onChange={addOrRemoveFromFavorites}
              disabled={loadingFavorites}
            />
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default ListItem;
