import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Hidden,
} from "@material-ui/core";
import { isChristmasMonth } from "../../../../util/UtilChirimbolos";
import { useTranslation } from "react-i18next";
import usePersistentStore from "../../../../core/stores/PersistentStore";

import { makeStyles } from "@material-ui/core/styles";
import DSuiteLogoNew from "../../../../solutions/login/components/DSuiteLogoNew";

export const useStyles = makeStyles(() => ({
  dialogContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },
  dialogContentImg: {
    backgroundImage: `url(/assets/img/holidays/christmas-header-light.webp)`,
    width: "100%",
    height: "95%",
    position: "absolute",
    top: "0",
    backgroundSize: "contain",
    opacity: "0.3",
  },
  dialogContentText: {
    fontWeight: "bold",
    fontSize: "2.5rem",
  },
  sidebarChristmasImg: {
    width: 46,
    cursor: "pointer",
  },
  sidebarChristmasImgAnimated: {
    width: 46,
    animation: "$shake 1.5s infinite",
    cursor: "pointer",
  },
  "@keyframes shake": {
    "0%": { transform: "translate(0, 0) rotate(0deg)" },
    "20%": { transform: "translate(-2px, 0) rotate(-2deg)" },
    "40%": { transform: "translate(2px, 0) rotate(2deg)" },
    "60%": { transform: "translate(-2px, 0) rotate(-2deg)" },
    "80%": { transform: "translate(2px, 0) rotate(2deg)" },
    "100%": { transform: "translate(0, 0) rotate(0deg)" },
  },
  "@keyframes lights": {
    "0%,50%": {
      backgroundPosition: "top",
    },
    "50.1%,100%": {
      backgroundPosition: "bottom",
    },
  },
}));

const IS_CHRISTMAS_MONTH = isChristmasMonth();

export default function ChristmasBtn() {
  const { t } = useTranslation();
  const classes = useStyles();
  const showedChristmasAnimation = usePersistentStore(
    (state) => state.showedChristmasAnimation
  );
  const disableChristmasAnimation = usePersistentStore(
    (state) => state.disableChristmasAnimation
  );
  const [openChristmasDialog, setOpenChristmasDialog] = useState(false);

  const handleClickChristmasTree = () => {
    setOpenChristmasDialog(true);
    disableChristmasAnimation();
  };

  if (IS_CHRISTMAS_MONTH) {
    return (
      <Hidden smDown>
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/holidays/christmas-sidebar.webp`}
          className={
            showedChristmasAnimation
              ? classes.sidebarChristmasImg
              : classes.sidebarChristmasImgAnimated
          }
          alt="christmas-tree-button"
          onClick={handleClickChristmasTree}
        />
        <Dialog
          open={openChristmasDialog}
          onClose={() => setOpenChristmasDialog(false)}
          aria-labelledby="christmas-greetings-dialog-title"
          aria-describedby="christmas-greetings-dialog-description"
          maxWidth="lg"
        >
          <DialogContent className={classes.dialogContent}>
            <DialogContentText
              id="christmas-greetings-dialog-description"
              variant="h4"
              className={classes.dialogContentText}
            >
              {t("CHRISTMAS_MESSAGE")}
            </DialogContentText>
            <div className={classes.dialogContentImg} />
            <DSuiteLogoNew maxWidth={500} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenChristmasDialog(false)}
              color="primary"
            >
              {t("CLOSE")}
            </Button>
          </DialogActions>
        </Dialog>
      </Hidden>
    );
  } else {
    return null;
  }
}
