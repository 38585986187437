import { makeStyles } from "@material-ui/core";

export const iconAuto = {
  width: "auto",
  height: "auto",
  padding: 1,
};

const baseTab = {
  width: 40,
  height: 40,
  borderRadius: 40,
  minWidth: 40,
  marginBlock: 10,
};

export const useStyles = makeStyles((theme) => ({
  environmentBtnMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
    width: "100%",
  },
  environmentBtnDesktop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    marginRight: "auto",
    marginLeft: "auto",
  },
  envButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  envButtonOpen: {},
  normalTab: {
    ...baseTab,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 5,
      marginBottom: 0,
      marginInline: 10,
      width: 50,
    },
  },
  activeTab: {
    ...baseTab,
    backgroundColor: theme.palette.content.mainColor,
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 5,
      marginBottom: 0,
      marginInline: 10,
      width: 50,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}CC`,
    },
  },
  divider: {
    height: "50%",
    alignSelf: "center",
  },
  customBadge: {
    backgroundColor: theme.palette.content.niceRed,
    color: "white",
  },
  emsLogoIcon: {
    width: 30,
  },
}));
