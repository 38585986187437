import React, { useContext } from "react";

import HomeTabButton from "../envButtons/HomeTabButton";
import WorkspaceButton from "../envButtons/WorkspaceButton";
import { useStyles } from "./SideBarStyles";
import { LayoutContext } from "../../../../core/providers/LayoutContext";
import InboxTabButton from "../envButtons/InboxTabButton";
import { DOCS_TAB_ENABLED, NEED_ENGINE_ON } from "../../../../util/Constants";
import RefreshItems from "../envButtons/RefreshItems";
import { Divider } from "@material-ui/core";
import NotificationsButtons from "../appBar/NotificationsButton";
import ToggleMenuButton from "../envButtons/ToggleMenuButton";
import DocsButton from "../envButtons/DocsButton";
import ProcessesButton from "../envButtons/ProcessesButton";
import FavsButton from "../envButtons/FavsButton";
import EmployeeStoreButton from "../envButtons/EmployeeStoreButton";
import ChristmasBtn from "../envButtons/ChristmasBtn";

export default function SideBar(props) {
  const {
    activeTab,
    handleTabClick,
    handleDrawerOpenState,
    openDrawer,
    handleProcessClick,
  } = props;

  const classes = useStyles();
  const { sidebarOpen } = useContext(LayoutContext);

  return (
    <div className={sidebarOpen ? classes.sidebarOpen : classes.sidebarClosed}>
      <div
        className={
          sidebarOpen ? classes.topButtonsOpen : classes.topButtonsClosed
        }
      >
        <ToggleMenuButton
          handleDrawerOpenState={handleDrawerOpenState}
          openDrawer={openDrawer}
        />
        <HomeTabButton
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          sidebarOpen={sidebarOpen}
        />
        {NEED_ENGINE_ON && (
          <InboxTabButton
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            sidebarOpen={sidebarOpen}
          />
        )}
        <WorkspaceButton
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          sidebarOpen={sidebarOpen}
        />
        <Divider orientation="horizontal" style={{ width: "75%" }} />
        <FavsButton handleProcessClick={handleProcessClick} />
        <Divider orientation="horizontal" style={{ width: "75%" }} />
        <ProcessesButton handleProcessClick={handleProcessClick} />
        <Divider orientation="horizontal" style={{ width: "75%" }} />
        {DOCS_TAB_ENABLED && (
          <DocsButton
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            sidebarOpen={sidebarOpen}
          />
        )}
        <Divider orientation="horizontal" style={{ width: "75%" }} />
        <EmployeeStoreButton sidebarOpen={sidebarOpen} />
        <NotificationsButtons />
      </div>
      <div className={classes.bottomButtons}>
        <ChristmasBtn />
        <RefreshItems />
      </div>
    </div>
  );
}
