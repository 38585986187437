import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  simulationDisclaimer: {
    fontStyle: "italic",
    color: theme.palette.semaphore.orange,
    fontWeight: "bold",
  },
}));

export default function SimulationRoundDisclaimer() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Typography variant="subtitle1" className={classes.simulationDisclaimer}>
      {t("EMPLOYEE_STORE.SIMULATION_REDONDEOS_DISCLAIMER")}
    </Typography>
  );
}
