export function isChristmasMonth() {
  try {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const isChristmasMonth = month === 12;
    return isChristmasMonth;
  } catch (_) {
    return false;
  }
}
