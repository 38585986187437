import { fetchInitialInfoApi } from "../../api/infoApi";

const createInfoSlice = (set) => ({
  loadingInfo: false,
  errorLoadingInfo: null,
  initialInfo: null,
  firstInitialInfoCompleted: false,
  showClosedEmsDialog: false,

  fetchInitialInfo: async (headers, logout) => {
    set({ loadingInfo: true, errorLoadingInfo: null });
    try {
      const { data, error } = await fetchInitialInfoApi(headers, logout);
      if (data) {
        set({
          initialInfo: data,
          loadingInfo: false,
          firstInitialInfoCompleted: true,
        });
      } else {
        set({
          errorLoadingInfo: error,
          loadingInfo: false,
          firstInitialInfoCompleted: true,
        });
      }
    } catch (error) {
      set({
        errorLoadingInfo: error.message,
        loadingInfo: false,
        firstInitialInfoCompleted: true,
      });
    }
  },

  openClosedEmsDialog: () => {
    set({ showClosedEmsDialog: true });
  },
  closeClosedEmsDialog: () => {
    set({ showClosedEmsDialog: false });
  },
});

export default createInfoSlice;
