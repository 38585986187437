import { isNumber } from "lodash";
import useEmployeeStoreState from "../EmployeeStoreState";
import useProducts from "./useProducts";

const usePagination = () => {
  const { pagination, setPagination, resetPagination } = useEmployeeStoreState(
    (state) => ({
      pagination: state.pagination,
      setPagination: state.setPagination,
      resetPagination: state.resetPagination,
    })
  );
  const { loadProducts } = useProducts();

  const totalItems = pagination?.totalItems || 0;
  const totalPages = pagination?.totalPages || 0;
  const page = pagination?.page;
  const size = pagination?.size;

  const resultsCounter = (() => {
    if (totalItems <= size) {
      return `${totalItems}`;
    }
    const start = (page - 1) * size + 1;
    const end = Math.min(page * size, totalItems);
    return `${start}-${end}`;
  })();

  const handlePageChange = (newPage) => {
    if (isNumber(newPage)) {
      setPagination(newPage, pagination?.size);
      loadProducts();
    }
  };

  const handlePageSizeChange = (newSize) => {
    if (isNumber(newSize)) {
      setPagination(1, newSize);
      loadProducts();
    }
  };

  return {
    pagination,
    setPagination,
    totalItems,
    totalPages,
    page,
    size,
    handlePageChange,
    handlePageSizeChange,
    resultsCounter,
    resetPagination,
  };
};

export default usePagination;
