import { isNil } from "lodash";
import { BASE_EMS_URL, handleApiResponse } from "./base";

export const fetchFiltersApi = async (
  headers,
  logout,
  showEmptyStock,
  showNoPrice
) => {
  let params = "";
  let added = false;

  if (!isNil(showEmptyStock) || !isNil(showNoPrice)) {
    params = "?";
  }

  if (!isNil(showEmptyStock)) {
    params += `showEmptyStock=${showEmptyStock}`;
    added = true;
  }

  if (!isNil(showNoPrice)) {
    params += `${added ? "&" : ""}showNoPrice=${showNoPrice}`;
  }

  const response = await fetch(`${BASE_EMS_URL}/filters${params}`, {
    method: "GET",
    headers: headers,
  });

  return handleApiResponse(response, logout);
};
