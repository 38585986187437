import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Paper,
  IconButton,
  Icon,
  Divider,
  Box,
} from "@material-ui/core";
import useEmployeeStoreState from "../../store/EmployeeStoreState";
import useCart from "../../store/hooks/useCart";
import useSimulation from "../../store/hooks/useSimulation";
import { isNil, toNumber } from "lodash";
import { formatPriceWithCountry } from "../../util/util-ems";
import { useTranslation } from "react-i18next";
import QuantityControlButton from "../QuantityControlButton";
import ProductImage from "../ProductImage";
import { AuthContext } from "../../../../core/providers/AuthContext";
import FilterAdminBadge from "../filters/FilterAdminBadge";
import useInfo from "../../store/hooks/useInfo";
import SimulationRoundDisclaimer from "../SimulationRoundDisclaimer";

const SHOW_SKU_MISSMATCH = false;

const useStyles = makeStyles((theme) => ({
  cartItem: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    position: "relative",
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
    border: "1px solid lightgray",
  },
  cartItemGrid: {
    alignItems: "stretch",
    minHeight: 170,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  image: {
    maxWidth: "100%",
    maxHeight: 120,
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      maxHeight: 80,
    },
  },
  deleteButton: {
    position: "absolute",
    top: 10,
    right: 10,
    color: theme.palette.semaphore.red,
  },
  quantityControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  quantityLabelContainer: {
    padding: "6px 12px",
    borderRadius: 6,
    color: "#FFF",
    backgroundColor: theme.palette.content.mainColor,
  },
  quantityLabelTypo: {
    fontWeight: "bold",
  },
  amountBtn: {
    border: "1px solid",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  simulationItemResponseInfoContainer: {
    padding: "12px",
    border: "1px solid lightgray",
    borderRadius: 10,
    backgroundColor: "#ffffff",
    margin: "8px",
    position: "relative",
    minHeight: 30,
  },
  simulationTypoInfo: {
    position: "absolute",
    top: -10,
    left: -10,
    border: "1px solid",
    borderRadius: "10px",
    padding: "3px",
    backgroundColor: theme.palette.content.mainColor,
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.content.mainColor,
      color: "white",
    },
  },
  typoMissmatchSku: {
    fontWeight: "bold",
    textDecoration: "underline",
    marginTop: 6,
    color: theme.palette.semaphore.orange,
    fontSize: 14,
  },
  typoBold: {
    fontWeight: "bold",
  },
  productInfo: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  priceInfo: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  contentColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  stockAdmin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
  },
}));

const ShoppingCartItem = (props) => {
  const {
    firm,
    firmDescription,
    title,
    price,
    quantity,
    minQuantity,
    imageUrl,
    sku,
    ean,
    distributionChannelCode,
    salesOrganizationCode,
    stock,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const { auth } = useContext(AuthContext);
  const isSimulating = useEmployeeStoreState((state) => state.isSimulating);
  const checkoutStep = useEmployeeStoreState((state) => state.checkoutStep);

  const { loadingCart, handleRemoveCartItem, handleAddCartItem } = useCart();
  const { getProductSimulationInfo, showSimulationDetails } = useSimulation();
  const { initialInfo } = useInfo();
  const isEmsAdmin = initialInfo?.isEmsAdmin === true;

  const productInfoInSimulation = getProductSimulationInfo({ sku, ean });

  const handleQuantityChange = (newQuantity) => {
    const params = {
      product: props,
      quantity: newQuantity,
    };
    handleAddCartItem(params);
  };

  const showDetails = showSimulationDetails === true;

  const buttonsDisabled = isSimulating || checkoutStep > 0;

  return (
    <Paper className={classes.cartItem} elevation={0}>
      <Grid container spacing={2} className={classes.cartItemGrid}>
        <Grid item xs={12} md={2}>
          <div className={classes.imageContainer}>
            <ProductImage firm={firm} imageUrl={imageUrl} title={title} />
          </div>
        </Grid>
        <Grid item xs={12} md={5} className={classes.productInfo}>
          <Typography variant="h4">{firmDescription}</Typography>
          <Typography variant="h5" color="textSecondary">
            {title}
          </Typography>
          <Typography variant="subtitle2">
            {`EAN: ${ean} | Org: ${salesOrganizationCode} | Canal: ${distributionChannelCode}`}
          </Typography>
          {!isNil(productInfoInSimulation) &&
            productInfoInSimulation?.sameSKU === false &&
            SHOW_SKU_MISSMATCH && (
              <Typography variant="body1" className={classes.typoMissmatchSku}>
                {t("EMPLOYEE_STORE.SIMULATION_SKU_MISSMATCH", {
                  simulationSku: productInfoInSimulation?.responseSkuNumber,
                })}
              </Typography>
            )}
        </Grid>
        <Grid item xs={12} md={3} className={classes.priceInfo}>
          {/* <Typography variant="h6">
            {t("EMPLOYEE_STORE.CART_ITEM_INITIAL_PRICE", {
              price: formatPriceWithCountry({
                price,
                country: auth?.emsCountry,
              }),
            })}
          </Typography> */}
          <Typography variant="h6">
            {t("EMPLOYEE_STORE.CART_ITEM_INITIAL_PRICE_TOTAL", {
              price: formatPriceWithCountry({
                price: price * quantity,
                country: auth?.emsCountry,
              }),
            })}
          </Typography>
          <Divider />
          {/* {!isNil(productInfoInSimulation) && (
            <Typography variant="h6">
              {t("EMPLOYEE_STORE.CART_ITEM_SIMULATION_PRICE", {
                price: formatPriceWithCountry({
                  price: productInfoInSimulation.responseUnityPrice,
                  country: auth?.emsCountry,
                }),
              })}
            </Typography>
          )} */}
          {!isNil(productInfoInSimulation) && (
            <Typography variant="h6" className={classes.typoBold}>
              {t("EMPLOYEE_STORE.CART_ITEM_SIMULATION_PRICE_TOTAL", {
                price: formatPriceWithCountry({
                  price: productInfoInSimulation.responseGrossValue,
                  country: auth?.emsCountry,
                }),
              })}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={2} className={classes.contentColumn}>
          <div className={classes.quantityControl}>
            <QuantityControlButton
              minQuantity={minQuantity}
              onChange={handleQuantityChange}
              disabled={buttonsDisabled || initialInfo?.isEmsClosed === true}
              defaultQuantity={quantity}
              stock={toNumber(stock)}
            />
            {isEmsAdmin ? (
              <Box className={classes.stockAdmin}>
                <Typography variant="h6">Stock ({stock})</Typography>
                <FilterAdminBadge showOnlyAdmin={true} />
              </Box>
            ) : null}
          </div>
        </Grid>
        {!isNil(productInfoInSimulation) ? (
          <Grid
            container
            spacing={2}
            className={classes.simulationItemResponseInfoContainer}
          >
            <Typography
              className={classes.simulationTypoInfo}
            >{`${productInfoInSimulation.simulationId} (${productInfoInSimulation.salesOrderItem})`}</Typography>

            {showDetails ? (
              <>
                {/* <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_ITEM_STRUC_DESC_AMOUNT")}
                  </Typography>
                  <Typography variant="body1">
                    {formatPriceWithCountry({
                      price: productInfoInSimulation.responseDescStructural,
                      country: auth?.emsCountry,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_ITEM_STRUC_DESC_PERC")}
                  </Typography>
                  <Typography variant="body1">
                    {productInfoInSimulation.responseStructuralDiscountPercent?.toFixed(
                      2
                    ) || 0.0}{" "}
                    %
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_ITEM_PROMO_DESC_AMOUNT")}
                  </Typography>
                  <Typography variant="body1">
                    {formatPriceWithCountry({
                      price: productInfoInSimulation.responseDescPromo,
                      country: auth?.emsCountry,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_ITEM_PROMO_DESC_PERC")}
                  </Typography>
                  <Typography variant="body1">
                    {productInfoInSimulation.responsePromotionalDiscountPercent?.toFixed(
                      2
                    ) || 0.0}{" "}
                    %
                  </Typography>
                </Grid> */}
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_PRICE_UNIT")}
                  </Typography>
                  <Typography variant="body1">
                    {formatPriceWithCountry({
                      price: productInfoInSimulation.responseUnityPrice,
                      country: auth?.emsCountry,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_QUANTITY")}
                  </Typography>
                  <Typography variant="body1">
                    {productInfoInSimulation.responseQtySalesOrder}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_PRICE_TOTAL_UNITS")}
                  </Typography>
                  <Typography variant="body1">
                    {formatPriceWithCountry({
                      price: productInfoInSimulation.responseItemPrice,
                      country: auth?.emsCountry,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1.5} />
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_ITEM_TOTAL_DESC")}
                  </Typography>
                  <Typography variant="body1">
                    {formatPriceWithCountry({
                      price: productInfoInSimulation.responseTotalDesc,
                      country: auth?.emsCountry,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_ITEM_CA")}
                  </Typography>
                  <Typography variant="body1">
                    {formatPriceWithCountry({
                      price: productInfoInSimulation.responseTotalCaSimulation,
                      country: auth?.emsCountry,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_ITEM_TAXES")}
                  </Typography>
                  <Typography variant="body1">
                    {formatPriceWithCountry({
                      price: productInfoInSimulation.responseIva,
                      country: auth?.emsCountry,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Typography variant="h6">
                    {t("EMPLOYEE_STORE.SIMULATION_TOTAL_PRICES_SIMU")}
                  </Typography>
                  <Typography variant="body1">
                    {formatPriceWithCountry({
                      price: productInfoInSimulation.responseGrossValue,
                      country: auth?.emsCountry,
                    })}
                  </Typography>
                </Grid>
                <SimulationRoundDisclaimer />
              </>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
      <IconButton
        size="small"
        className={classes.deleteButton}
        disabled={buttonsDisabled || loadingCart}
        onClick={() =>
          handleRemoveCartItem({
            product: props,
          })
        }
      >
        <Icon className="fas fa-trash-alt" />
      </IconButton>
    </Paper>
  );
};

export default ShoppingCartItem;
