import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EmployeeStorePageWrapper from "../layout/EmployeeStorePageWrapper";
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import HistoryItemContainer from "../components/history/HistoryItemContainer";
import { useTranslation } from "react-i18next";
import useHistory from "../store/hooks/useHistory";
import {
  filter,
  isArray,
  isEmpty,
  isNil,
  size,
  some,
  toLower,
  toString,
} from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { formatDateToHumanReadableWithLang } from "../util/util-ems";
import { useLocale } from "../../../core/hooks/useLocale";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: "auto",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative",
  },
  firstBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 12,
    width: "100%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      display: "flex",
      alignItems: "flex-start",
    },
  },
  contentContainer: {
    width: "75%",
    maxWidth: 1350,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
}));

function filterHistoryItemsOptions(options, state, locale) {
  if (isNil(options) || isNil(state)) {
    return options;
  }

  const input = toLower(state.inputValue);

  return options.filter((option) => {
    return (
      toString(option.emsIdCompra).includes(input) ||
      toLower(
        formatDateToHumanReadableWithLang({
          dateString: option?.purchaseDate,
          lang: locale,
        })
      ).includes(input) ||
      toLower(option.parentSimulationId).includes(input) ||
      // products
      some(option.products, function (o) {
        return toLower(o.ean).includes(input);
      }) ||
      // orders
      some(option.sapOrders, function (o) {
        return (
          toLower(o.sapOrderId).includes(input) ||
          toLower(o.emsOrderId).includes(input)
        );
      }) ||
      // simulations
      some(option.simulations, function (o) {
        return toLower(o.simulationId).includes(input);
      })
    );
  });
}

export default function EmployeeStoreHistoryPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [locale] = useLocale();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    historyItems,
    loadingHistoryItems,
    errorLoadingHistoryItems,
    handleFetchHistoryItems,
    loadedHistoryItems,
  } = useHistory();

  useEffect(() => {
    if (
      (isNil(historyItems) || isEmpty(historyItems)) &&
      !loadingHistoryItems &&
      isNil(errorLoadingHistoryItems) &&
      !loadedHistoryItems
    ) {
      handleFetchHistoryItems();
    }
  }, [
    errorLoadingHistoryItems,
    handleFetchHistoryItems,
    historyItems,
    loadingHistoryItems,
    loadedHistoryItems,
  ]);

  const hasHistoryItems =
    !isNil(historyItems) && isArray(historyItems) && !isEmpty(historyItems);

  const filteredItems = useMemo(() => {
    if (!isNil(value) && !isEmpty(value) && hasHistoryItems) {
      return filter(historyItems, { emsIdCompra: value?.emsIdCompra });
    }

    return historyItems;
  }, [hasHistoryItems, historyItems, value]);

  return (
    <EmployeeStorePageWrapper>
      <div className={classes.root}>
        {loadingHistoryItems ? (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box className={classes.contentContainer}>
            <Box className={classes.titleBox}>
              <Box className={classes.firstBox}>
                <Typography variant="h4">
                  {t("EMPLOYEE_STORE.HEADER_BTN_HISTORY")} (
                  {size(filteredItems)})
                </Typography>
                <IconButton onClick={handleFetchHistoryItems}>
                  <Icon className="fas fa-sync" />
                </IconButton>
              </Box>

              {hasHistoryItems ? (
                <Autocomplete
                  openOnFocus
                  autoHighlight
                  id="search-auto-cmplete"
                  options={historyItems}
                  getOptionLabel={(option) =>
                    `${
                      option.emsIdCompra
                    } - ${formatDateToHumanReadableWithLang({
                      dateString: option.purchaseDate,
                      lang: locale,
                    })} `
                  }
                  style={{ width: isSmDown ? "100%" : 310 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("HEADER_SEARCH_PLACEHOLDER")}
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  filterOptions={(options, state) =>
                    filterHistoryItemsOptions(options, state, locale)
                  }
                />
              ) : null}
            </Box>

            {hasHistoryItems
              ? filteredItems.map((historyItem, index) => (
                  <HistoryItemContainer key={index} {...historyItem} />
                ))
              : null}
          </Box>
        )}
      </div>
    </EmployeeStorePageWrapper>
  );
}
