import { BASE_EMS_URL, handleApiResponse } from "./base";

const PAYMENTS_CREATE_TRANSACTION = "/payments/create-transaction";
const PAYMENTS_COMMIT_TRANSACTION = "/payments/commit-transaction";
const PAYMENTS_CANCEL_ORDER = "/payments/cancel-order";

export const createTransactionApi = async ({
  headers,
  logout,
  paymentSystem,
  body,
}) => {
  const response = await fetch(
    `${BASE_EMS_URL}${PAYMENTS_CREATE_TRANSACTION}?payment-system=${paymentSystem}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    }
  );

  return handleApiResponse(response, logout);
};

export const commitTransactionApi = async ({
  headers,
  logout,
  body,
  paymentSystem,
}) => {
  const response = await fetch(
    `${BASE_EMS_URL}${PAYMENTS_COMMIT_TRANSACTION}?payment-system=${paymentSystem}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    }
  );

  return handleApiResponse(response, logout);
};

export const cancelOrderApi = async ({ headers, logout, emsCompraId }) => {
  const response = await fetch(
    `${BASE_EMS_URL}${PAYMENTS_CANCEL_ORDER}?idcompra=${emsCompraId}`,
    {
      method: "POST",
      headers: headers,
    }
  );

  return handleApiResponse(response, logout);
};
