import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";

import useSimulation from "../../store/hooks/useSimulation";
import { Box, Icon, makeStyles, Typography } from "@material-ui/core";
import { copyTextToClipboard } from "../../../../util/util-io";

const useStyles = makeStyles(() => ({
  dialog: {
    borderLeft: "15px solid #dc2a2a",
  },
  dialogTitle: {
    gap: "6px",
    fontSize: "26px",
    display: "flex",
    alignItems: "center",
  },
  copyCodeBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "6px",
    marginTop: "12px",
  },
  body2: {
    marginTop: 12,
    fontStyle: "italic",
  },
  btnCopy: {},
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
};
export default function SimulationErrorDialog() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    parentSimulationId,
    showErrorDialogSimulating,
    closeShowErrorDialogSimulation,
  } = useSimulation();

  return (
    <Dialog
      open={showErrorDialogSimulating}
      aria-labelledby="simulation-error-dialog-title"
      aria-describedby="simulation-error-dialog-description"
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle
        id="simulation-error-dialog-title"
        classes={{
          root: classes.dialogTitle,
        }}
        disableTypography
      >
        <Icon
          className="fas fa-exclamation-triangle"
          style={{ ...commonIconStyles, color: "#dc2a2a" }}
        />
        {t("EMPLOYEE_STORE.SIMULATION_ERROR_DIALOG_TITLE")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          {t("EMPLOYEE_STORE.SIMULATION_MAX_ATTEMPTS_REACHED")}
        </Typography>
        <Typography variant="subtitle1" className={classes.body2}>
          {t("EMPLOYEE_STORE.SIMULATION_ERROR_DIALOG_BODY_2")}
        </Typography>
        <Box className={classes.copyCodeBox}>
          <Typography>{parentSimulationId}</Typography>
          <Button
            endIcon={<Icon className="fas fa-copy" />}
            variant="outlined"
            className={classes.btnCopy}
            onClick={() => {
              copyTextToClipboard(parentSimulationId);
            }}
          >
            {t("EMPLOYEE_STORE.SIMULATION_ERROR_DIALOG_COPY_BTN")}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeShowErrorDialogSimulation}
          className={classes.btnCopy}
        >
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
