import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useBlockLayout,
  useResizeColumns,
  useRowSelect,
} from "react-table";
import * as _ from "lodash";

import GlobalFilter from "../components/filter/GlobalFilter";
import Pagination from "../components/Pagination";
import { MainTableSelecionCheck } from "../components/MainTableSelectionCheck";
import { useTranslation } from "react-i18next";
import {
  INPUT_TYPE_DATETIMEPICKER,
  NEW_GRID_DISTRIBUTION_SOLUTIONS,
  PAGINATION_DEFAULT_MAX_SIZE,
  PAGINATION_ROWS_TO_SHOW,
} from "../../../../util/Constants";
import { ThemeContext } from "../../../../core/providers/ThemeContext";
import {
  existInRowValues,
  getEnabledSelectionRow,
  handleDeleteRowByValue,
} from "../../../../util/UtilTable";
import { Tooltip } from "@material-ui/core";
import { getUTCTooltip } from "../../../../util/UtilDates";
import {
  CALCULATION_TYPE_PROPORTIONAL,
  calculateColumnWidths,
} from "./calculations";
import { useFeatureOnSolutions } from "../../../../core/hooks/useFeatureOnSolutions";
import { useRefWidth } from "../../../../core/hooks/useRefWidth";

const USED_CALCULATION_TYPE = CALCULATION_TYPE_PROPORTIONAL;

export default function Table(props) {
  const {
    columns,
    data,
    updateDataTable,
    skipPageReset,
    defaultColumn,
    operation,
    formContext,
    handleDeleteSelectedRows,
    handleAddNewRow,
    enableAddRow: enableAddRowProp,
    enableDeleteRow,
    handleChargeMockData,
    processServerResponse,
    isSettingValueInServer,
    id,
    colDisableDeleteRow,
    showSearchBarRow,
    titleBlock,
    maxRows,
    shadowStatus,
    denyAddRow,
    disableMassiveGridLoad,
    hideRadioButtonsGrid,
    formSchema,
    formData,
    isBlockDisabledByWizard,
  } = props;

  const { t } = useTranslation();
  const isNewGridDistributionEnabled = useFeatureOnSolutions(
    NEW_GRID_DISTRIBUTION_SOLUTIONS
  );

  const globalFilter = (rows, columns, filterValue) => {
    return (
      rows &&
      rows.filter((row) => {
        const objValues = Object.values(row.values);
        return objValues !== undefined
          ? existInRowValues(objValues, filterValue)
          : true;
      })
    );
  };

  const dataToUse = useMemo(() => {
    if (!_.isNil(data) && _.isArray(data) && !_.isEmpty(data)) {
      return data;
    }
    return [];
  }, [data]);

  const pageSizeCalc = useMemo(() => {
    if (
      dataToUse &&
      dataToUse.length > 0 &&
      dataToUse.length < PAGINATION_DEFAULT_MAX_SIZE
    ) {
      return dataToUse.length;
    } else {
      return PAGINATION_DEFAULT_MAX_SIZE;
    }
  }, [dataToUse]);

  const isLongData = useMemo(() => {
    const dataLength = dataToUse?.length;
    return dataLength && dataLength > 0 && dataLength > PAGINATION_ROWS_TO_SHOW
      ? true
      : false;
  }, [dataToUse]);

  const enableAddRow = useMemo(() => {
    return enableAddRowProp && !isLongData;
  }, [enableAddRowProp, isLongData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    visibleColumns,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
    rows,
  } = useTable(
    {
      columns,
      data: dataToUse,
      defaultColumn,
      globalFilter,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateDataTable isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateDataTable,
      initialState: {
        pageSize: pageSizeCalc,
        hiddenColumns: columns.map((col) => {
          if (col && col.hidden) {
            const id = col.accessor;
            return id;
          } else {
            return null;
          }
        }),
      },
      operation,
      formContext,
      handleDeleteSelectedRows,
      handleAddNewRow,
      enableAddRow,
      enableDeleteRow,
      handleChargeMockData,
      //handleFormDataChange,
      processServerResponse,
      isSettingValueInServer,
      showSearchBarRow,
    },
    useBlockLayout,
    useResizeColumns,
    useGlobalFilter, // MUST be placed before usePagination
    usePagination, // MUST be placed after useGlobalFilter
    useRowSelect,
    (hooks) => {
      if (_.isNil(hideRadioButtonsGrid) || hideRadioButtonsGrid === false) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: (props) => {
              const enabledRowDeletion = getEnabledSelectionRow({
                enableDeleteRow,
                operation: props?.operation || operation,
              });

              const { getToggleAllRowsSelectedProps } = props;
              const someDisabledRowByValue = false;
              const allSelectEnabled =
                enabledRowDeletion && !someDisabledRowByValue && !isLongData;

              return (
                <>
                  <MainTableSelecionCheck
                    {...getToggleAllRowsSelectedProps(allSelectEnabled)}
                    title={t("FORM_TABLE_SELECT_ALL")}
                    enabled={allSelectEnabled}
                  />
                </>
              );
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row, operation: tableStateOperation }) => {
              const enabledRowDeletion = getEnabledSelectionRow({
                enableDeleteRow,
                operation: tableStateOperation,
              });

              const disabledRowByValue = handleDeleteRowByValue({
                row,
                colDisableDeleteRow,
              });

              const finalEnabled =
                enabledRowDeletion && !disabledRowByValue && !isLongData;

              return (
                <>
                  <MainTableSelecionCheck
                    {...row.getToggleRowSelectedProps(finalEnabled)}
                    title={t("FORM_TABLE_SELECT_ONE")}
                    enabled={finalEnabled}
                  />
                </>
              );
            },
            width: 50,
            overrideWidth: true,
          },
          ...columns,
        ]);
      }
    }
  );

  //Update page size when page size calculations ends
  useEffect(() => {
    setPageSize(pageSizeCalc);
  }, [pageSizeCalc, setPageSize]);

  //Row REF
  const tableRef = useRef(null);
  const lastRowRef = useRef(null);
  //const [added, setAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);

  /*useEffect(() => {
    if (dataToUse && dataToUse.length > 0 && added === true) {
      scrollToLastRow();
    }
    return () => null;
  }, [lastRowRef, dataToUse, added]); */

  //Create new row and scroll to it
  async function handleAddRowAndScroll() {
    setLoading(!loading);
    if (!_.isNil(rows) && rows.length > 0) {
    //  setAdded(true);
      const lastRow = rows[rows.length - 1]?.original;
      const copyOfRow = _.clone(lastRow);
      delete copyOfRow["line"];
      const compacted = _.pickBy(copyOfRow);
      if (!_.isNil(compacted) && !_.isEmpty(compacted)) {
        await handleAddNewRow();
        setTimeout(() => {
          const pageToGo = pageCount - 1;
          gotoPage(pageToGo);
        }, 10);
      }
    } else {
      await handleAddNewRow();
      setTimeout(() => {
        const pageToGo = pageCount - 1;
        gotoPage(pageToGo);
      }, 10);
    }
   // await scrollToLastRow();
    setLoading(false);
  }

/*  function scrollToLastRow() {
    if (lastRowRef && lastRowRef.current) {
      lastRowRef.current.scrollIntoView();
      if (tableRef && tableRef.current) {
        tableRef.current.scrollIntoView();
      }
    }
  }*/

  const getColumnSpecialStyles = useCallback(
    (column) => {
      if (!column) {
        return null;
      } else {
        const { labelStyle, align } = column;
        if (labelStyle) {
          const { color, fontSize, fontStyle, fontWeight } = labelStyle;
          const selectedColor =
            color && color.hasOwnProperty(theme) && color[theme];
          return {
            fontSize: !_.isNil(fontSize) && fontSize,
            fontStyle: !_.isNil(fontStyle) && fontStyle,
            fontWeight: !_.isNil(fontWeight) && fontWeight,
            color: selectedColor,
            textAlign: !_.isNil(align) && align,
          };
        } else if (!_.isNil(align)) {
          return {
            textAlign: align,
          };
        } else {
          return null;
        }
      }
    },
    [theme]
  );

  const tableWidth = useRefWidth({ ref: tableRef, minusOffset: 3 });
  const cantColumns = _.size(visibleColumns);
  const defaultDistributedWidthCols =
    !_.isNil(tableWidth) &&
    _.isNumber(tableWidth) &&
    tableWidth > 0 &&
    !_.isNil(cantColumns) &&
    _.isNumber(cantColumns) &&
    cantColumns > 0
      ? tableWidth / cantColumns
      : undefined;

  const mapWidthsMemo = useMemo(() => {
    if (
      !_.isNil(visibleColumns) &&
      !_.isEmpty(visibleColumns) &&
      !_.isNil(tableWidth) &&
      _.isNumber(tableWidth) &&
      tableWidth > 0 &&
      isNewGridDistributionEnabled === true
    ) {
      const columnsToCalculate =
        !_.isNil(visibleColumns) &&
        _.isArray(visibleColumns) &&
        !_.isEmpty(visibleColumns)
          ? visibleColumns.map((x) => {
              const widthColumn = x?.width;
              if (
                !_.isNil(x) &&
                (_.isNil(widthColumn) ||
                  _.isNaN(widthColumn) ||
                  widthColumn === 0 ||
                  _.trim(toString(widthColumn)) === "")
              ) {
                return {
                  ...x,
                  width: 150,
                  overrideWidth: true,
                };
              } else {
                return x;
              }
            })
          : [];

      return calculateColumnWidths(USED_CALCULATION_TYPE, {
        columns: columnsToCalculate,
        totalTableWidth: tableWidth - 1,
        defaultColumnWidth: defaultDistributedWidthCols,
      });
    } else {
      return new Map();
    }
  }, [
    defaultDistributedWidthCols,
    tableWidth,
    visibleColumns,
    isNewGridDistributionEnabled,
  ]);

  return (
    <>
      <table
        {...getTableProps()}
        ref={tableRef}
        className="table"
        id={id}
        key={id}
      >
        <thead>
          {!_.isNil(showSearchBarRow) && showSearchBarRow === false ? null : (
            <tr
              style={{
                padding: 10,
                borderRadius: 10,
                width: "100%",
              }}
            >
              <th
                colSpan={visibleColumns.length}
                style={{
                  borderRadius: "10px 10px 0px 0px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <GlobalFilter
                  key={`${id}_globalFilter`}
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  rows={rows}
                  handleAddNewRow={handleAddRowAndScroll}
                  enableAddRow={enableAddRow}
                  handleChargeMockData={handleChargeMockData}
                  selectedRowIds={selectedRowIds}
                  handleDeleteSelectedRows={handleDeleteSelectedRows}
                  operation={operation}
                  loading={loading}
                  setGlobalFilter={setGlobalFilter}
                  globalFilter={globalFilter}
                  idTable={id}
                  selectedFlatRows={selectedFlatRows}
                  columns={columns}
                  titleBlock={titleBlock}
                  enableDeleteRow={enableDeleteRow}
                  maxRows={maxRows}
                  shadowStatus={shadowStatus}
                  denyAddRow={denyAddRow}
                  formInstanceId={formContext?.formInstanceId}
                  formContext={formContext}
                  processServerResponse={processServerResponse}
                  disableMassiveGridLoad={disableMassiveGridLoad}
                  formSchema={formSchema}
                  formData={formData}
                  isBlockDisabledByWizard={isBlockDisabledByWizard}
                />
                <Pagination
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  pageCount={pageCount}
                  pageOptions={pageOptions}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pageIndex={pageIndex}
                />
              </th>
            </tr>
          )}

          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps();
            return (
              <tr
                {...headerGroupProps}
                className="trHeadCells"
                style={
                  isNewGridDistributionEnabled === true
                    ? { ...headerGroupProps.style, width: "auto" }
                    : { ...headerGroupProps.style }
                }
              >
                {headerGroup.headers.map((column, index) => {
                  const { style: styleProp } = column.getHeaderProps();

                  if (isNewGridDistributionEnabled === true) {
                    const calculatedWidthPixels =
                      !_.isNil(mapWidthsMemo) &&
                      _.isMap(mapWidthsMemo) &&
                      !_.isEmpty(mapWidthsMemo) &&
                      mapWidthsMemo.has(column?.id)
                        ? mapWidthsMemo.get(column?.id)
                        : column?.width;

                    Object.assign(styleProp, { width: calculatedWidthPixels });
                  }

                  const specialStyles = getColumnSpecialStyles(column);
                  const mixedStyles = {
                    ...styleProp,
                    ...specialStyles,
                  };

                  if (isNewGridDistributionEnabled === true) {
                    Object.assign(mixedStyles, {
                      width: styleProp?.width,
                    });
                  }

                  const isRequired = column?.required ? true : false;
                  const columnResizerProps =
                    !_.isNil(column) &&
                    !_.isNil(column.getResizerProps) &&
                    _.isFunction(column.getResizerProps)
                      ? column.getResizerProps()
                      : {};
                  return (
                    <th
                      {...column.getHeaderProps()}
                      style={mixedStyles}
                      className="th"
                    >
                      {isRequired ? (
                        `${column.render("Header")} *`
                      ) : (
                        <Tooltip
                          title={
                            column?.inputType === INPUT_TYPE_DATETIMEPICKER
                              ? getUTCTooltip()
                              : ""
                          }
                          placement="top"
                        >
                          <div>{column.render("Header")}</div>
                        </Tooltip>
                      )}
                      {/* Use column.getResizerProps to hook up the events correctly */}
                      {index + 1 < headerGroup.headers.length && (
                        <div
                          {...column.getResizerProps()}
                          className={
                            isNewGridDistributionEnabled === true
                              ? `resizer`
                              : `resizer ${
                                  column.isResizing ? "isResizing" : ""
                                }`
                          }
                          style={
                            isNewGridDistributionEnabled === true
                              ? {
                                  ...columnResizerProps?.style,
                                  cursor: "default",
                                  pointerEvents: "none",
                                }
                              : { ...columnResizerProps?.style }
                          }
                        />
                      )}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody {...getTableBodyProps()} className="tbody">
          {page.map((row, i) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <tr
                {...rowProps}
                className="tr"
                ref={
                  page && page.length > 0 && page.length - 1 === i
                    ? lastRowRef
                    : null
                }
                style={
                  isNewGridDistributionEnabled === true
                    ? { ...rowProps.style, width: "auto" }
                    : { ...rowProps.style }
                }
              >
                {row.cells.map((cell) => {
                  const { style } = cell.getCellProps();
                  Object.assign(style, { display: "flex" });

                  if (!_.isNil(cell?.column?.align)) {
                    Object.assign(style, { textAlign: cell?.column?.align });
                  }

                  if (isNewGridDistributionEnabled === true) {
                    const calculatedWidthPixels =
                      !_.isNil(mapWidthsMemo) &&
                      _.isMap(mapWidthsMemo) &&
                      !_.isEmpty(mapWidthsMemo) &&
                      mapWidthsMemo.has(cell?.column?.id)
                        ? mapWidthsMemo.get(cell?.column?.id)
                        : cell?.column?.width;

                    Object.assign(style, { width: calculatedWidthPixels });
                  }

                  return (
                    <td {...cell.getCellProps()} className="td" style={style}>
                      {cell &&
                      cell.column &&
                      cell.column.inputType &&
                      _.has(defaultColumn, cell.column.inputType)
                        ? cell.render(cell?.column?.inputType)
                        : cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
