import React, { useContext, useEffect, useMemo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EmployeeStorePageWrapper from "../layout/EmployeeStorePageWrapper";
import {
  List,
  ListItem,
  Typography,
  Button,
  Box,
  Icon,
  useMediaQuery,
  CircularProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import useEmployeeStoreState from "../store/EmployeeStoreState";
import ProductQuickViewModal from "../components/ProductQuickViewModal";
import { useTranslation } from "react-i18next";
import useFavorites from "../store/hooks/useFavorites";
import {
  isArray,
  isEmpty,
  isNil,
  size,
  toString,
  toLower,
  filter,
} from "lodash";
import { utcDateToLocal } from "../../../util/UtilDates";
import {
  DATE_TIME_PATTERN,
  FRONT_DATE_TIME_PATTERN,
} from "../../../util/Constants";
import { format, parse } from "date-fns";
import ProductImage from "../components/ProductImage";
import ProductPriceAndStock from "../components/ProductPriceAndStock";
import ProductActions from "../components/ProductActions";
import { Autocomplete } from "@material-ui/lab";
import { AuthContext } from "../../../core/providers/AuthContext";
import { formatPriceWithCountry } from "../util/util-ems";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "center",
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  list: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 1000,
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 1200,
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: 1400,
    },
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "2px solid #e0e0e0",
    backgroundColor: "white",
    padding: theme.spacing(2),
    marginBottom: 6,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  listItemDisabled: {
    background:
      "repeating-linear-gradient(-45deg, #c6c6c687, #ffffff 5px, #ffffff 5px, #ebebeb96 25px)",
    borderRadius: "10px",
  },
  clickableInfo: {
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
  productInfo: {
    flex: 1,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    //width: 250
  },
  button: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5, 1),
    fontSize: "0.75rem",
    width: "100%",
  },
  addToCartButton: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    border: `1px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#FFF",
    },
  },
  viewItemButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  deleteFavItemButton: {
    border: `1px solid ${theme.palette.semaphore.red}`,
    color: theme.palette.semaphore.red,
  },
  deleteIcon: {
    color: theme.palette.semaphore.red,
  },
  typoPrice: {
    fontWeight: 700,
  },
  itemNoAvailableBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 12,
  },
  placeholderBox: {
    border: "1px solid",
    opacity: "0.5",
    padding: "32px",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 12,
    width: "100%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      display: "flex",
      alignItems: "flex-start",
    },
  },
  firstBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
  },
}));

const iconImagePlaceholder = {
  width: "auto",
  height: "auto",
  padding: 2,
  fontSize: 90,
};

function safeFormatting(dateAdded) {
  if (isNil(dateAdded)) {
    return null;
  }

  try {
    const formatted = format(
      utcDateToLocal(parse(dateAdded, DATE_TIME_PATTERN, new Date())),
      FRONT_DATE_TIME_PATTERN
    );

    return formatted;
  } catch (_) {
    return null;
  }
}

function filterFavoritesOptions(options, state) {
  if (isNil(options) || isNil(state)) {
    return options;
  }

  const input = toLower(state.inputValue);

  return options.filter((option) => {
    return (
      toLower(toString(option?.product?.ean)).includes(input) ||
      toLower(toString(option?.product?.title)).includes(input) ||
      toLower(toString(option?.product?.price)).includes(input)
    );
  });
}

export default function EmployeeStoreFavsPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const { auth } = useContext(AuthContext);

  const selectedProduct = useEmployeeStoreState(
    (state) => state.selectedProduct
  );
  const openQuickView = useEmployeeStoreState((state) => state.openQuickView);
  const closeQuickView = useEmployeeStoreState((state) => state.closeQuickView);
  const {
    favorites,
    removeFavorite,
    getFavorites,
    loadingFavorites,
    loadedFavoritedItems,
  } = useFavorites();

  useEffect(() => {
    if (
      (isNil(favorites) || isEmpty(favorites)) &&
      !loadingFavorites &&
      !loadedFavoritedItems
    ) {
      getFavorites();
    }
  }, [getFavorites, favorites, loadingFavorites, loadedFavoritedItems]);

  const hasFavsItems =
    !isNil(favorites) && isArray(favorites) && !isEmpty(favorites);

  const filteredItems = useMemo(() => {
    if (!isNil(value) && !isEmpty(value) && hasFavsItems) {
      return filter(favorites, function (o) {
        return !isNil(o) && o?.product?.ean === value?.product?.ean;
      });
    }

    return favorites;
  }, [hasFavsItems, favorites, value]);

  return (
    <EmployeeStorePageWrapper>
      <div className={classes.root}>
        {loadingFavorites ? (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List className={classes.list}>
            <Box className={classes.titleBox}>
              <Box className={classes.firstBox}>
                <Typography variant="h4">
                  {`${t("EMPLOYEE_STORE.HEADER_BTN_FAVS")} (${size(
                    filteredItems
                  )})`}
                </Typography>
                <IconButton onClick={() => getFavorites()}>
                  <Icon className="fas fa-sync" />
                </IconButton>
              </Box>
              {hasFavsItems ? (
                <Autocomplete
                  openOnFocus
                  autoHighlight
                  id="search-favs-auto-cmplete"
                  options={favorites}
                  getOptionLabel={(option) =>
                    `${option?.product?.title} ${formatPriceWithCountry({
                      price: option?.product?.price,
                      country: auth?.emsCountry,
                    })} -  (${option?.product?.ean})`
                  }
                  style={{ width: isSmDown ? "100%" : 310 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("HEADER_SEARCH_PLACEHOLDER")}
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  filterOptions={(options, state) =>
                    filterFavoritesOptions(options, state)
                  }
                />
              ) : null}
            </Box>

            {size(favorites) === 0 && (
              <p>{t("EMPLOYEE_STORE.NO_FAVORITE_PRODUCTS")}</p>
            )}

            {size(filteredItems) > 0 &&
              filteredItems.map((fav) => {
                const product = fav?.product;
                const formattedAdded = safeFormatting(fav?.dateAdded);

                if (!isNil(product)) {
                  return (
                    <ListItem key={product.sku} className={classes.listItem}>
                      <Box
                        display="flex"
                        alignItems={isXsDown ? "flex-start" : "center"}
                        flexDirection={isXsDown ? "column" : "row"}
                        flex={1}
                        onClick={() => openQuickView(product)}
                        role="button"
                        className={classes.clickableInfo}
                      >
                        <ProductImage
                          firm={product.firm}
                          imageUrl={product.imageUrl}
                          title={product.title}
                        />

                        <Box className={classes.productInfo}>
                          <Typography variant="h5">
                            {product.firmDescription}
                          </Typography>
                          <Typography variant="subtitle1">
                            {product.title}
                          </Typography>
                          <ProductPriceAndStock {...product} />
                          {!isNil(formattedAdded) ? (
                            <Typography variant="subtitle1">
                              {t("EMPLOYEE_STORE.ADDED")} {formattedAdded}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                      <Box className={classes.buttons}>
                        <ProductActions item={product} isShowedInFav={true} />
                        <Button
                          variant="outlined"
                          className={`${classes.button} ${classes.deleteFavItemButton}`}
                          startIcon={
                            <Icon
                              className={`fas fa-heart-broken ${classes.deleteIcon}`}
                            />
                          }
                          onClick={() => removeFavorite(product)}
                        >
                          {t("EMPLOYEE_STORE.RMV_FROM_FAVORITES")}
                        </Button>
                      </Box>
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem
                      key={fav.sku}
                      className={`${classes.listItem} ${classes.listItemDisabled}`}
                    >
                      <Box className={classes.itemNoAvailableBox}>
                        <Box className={classes.placeholderBox}>
                          <Icon
                            className="fas fa-image"
                            style={iconImagePlaceholder}
                          />
                        </Box>
                        <div>
                          <Typography variant="h5">
                            {t("EMPLOYEE_STORE.PRODUCT_NOT_AVAILABLE")}
                          </Typography>
                          <Typography variant="h6">EAN: {fav.ean}</Typography>
                          <Typography variant="subtitle1">
                            {t("EMPLOYEE_STORE.ADDED")} {formattedAdded}
                          </Typography>
                        </div>
                      </Box>
                      <Box className={classes.buttons}>
                        <Button
                          variant="outlined"
                          className={`${classes.button} ${classes.deleteFavItemButton}`}
                          startIcon={
                            <Icon
                              className={`fas fa-heart-broken ${classes.deleteIcon}`}
                            />
                          }
                          onClick={() =>
                            removeFavorite({ ean: fav.ean, sku: fav.sku })
                          }
                        >
                          {t("EMPLOYEE_STORE.RMV_FROM_FAVORITES")}
                        </Button>
                      </Box>
                    </ListItem>
                  );
                }
              })}
          </List>
        )}
        <ProductQuickViewModal
          product={selectedProduct}
          open={Boolean(selectedProduct)}
          onClose={closeQuickView}
        />
      </div>
    </EmployeeStorePageWrapper>
  );
}
