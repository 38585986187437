import { makeStyles } from "@material-ui/core/styles";

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",

    position: "relative",
    overflowY: "hidden",
    overflowX: "hidden",
    backgroundImage: 'url("/assets/img/login/dsuite-login-back.webp")',
    backgroundSize: "cover",
    backgroundPosition: "bottom",

    [theme.breakpoints.down("md")]: {
      backgroundPosition: "left",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
  contentBox: {
    color: "#FFFFFF",
    border: "3px solid #06588A",
    padding: theme.spacing(3),
    boxShadow: "0 0 3px white !important",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    minHeight: "350px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "1200px",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
    marginBottom: 16,
    color: "#323236",
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
    color: "#323236",
  },
  title: {
    fontWeight: "bold",
  },
  descriptionText: {
    fontStyle: "italic",
    marginTop: 16,
  },
  gridContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
  },
  gridItem: {
    cursor: "pointer",
  },
  profileCard: {
    color: "#323236",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: theme.spacing(2),
    textAlign: "start",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    transition: "transform 0.2s",
    height: "200px",
    "&:hover": {
      transform: "scale(1.02)",
    },
    position: "relative"
  },
  profileIndexText: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
}));
