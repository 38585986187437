import React, { useMemo } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import { isEmpty, isNil } from "lodash";

import useInfo from "../../store/hooks/useInfo";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  listBlocks: {
    marginTop: 4,
    paddingBlock: 0,
  },
  contactBox: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
}));

const MAP_BLOCKS = {
  blockCredit: "BLOCK CREDIT",
  blockCustomer: "BLOCK CUSTOMER",
  blockDeletion: "BLOCK DELETION",
  blockFlag: "BLOCK FLAG",
};

export default function EmployeeStoreLayoutBlocksDialog() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { initialInfo, firstInitialInfoCompleted, handleCloseDialog } =
    useInfo();

  const shouldOpenDialog = useMemo(() => {
    if (!isNil(initialInfo) && firstInitialInfoCompleted === true) {
      const { blockCredit, blockCustomer, blockDeletion, blockFlag } =
        initialInfo;
      return blockCredit || blockCustomer || blockDeletion || blockFlag;
    }

    return false;
  }, [initialInfo, firstInitialInfoCompleted]);

  const blockList = useMemo(() => {
    if (isNil(initialInfo) || isEmpty(initialInfo)) {
      return [];
    }

    return Object.entries(initialInfo)
      .filter(
        ([key, value]) => value && key.startsWith("block") && value === true
      )
      .map(([key]) => MAP_BLOCKS[key]);
  }, [initialInfo]);

  if (!shouldOpenDialog) {
    return null;
  }

  return (
    <Dialog open={true} maxWidth="lg">
      <DialogContent>
        <DialogContentText variant="h5">
          {t("EMPLOYEE_STORE.BLOCK_DIALOG_TITLE")}
        </DialogContentText>

        <Typography variant="h6">
          {t("EMPLOYEE_STORE.BLOCK_DIALOG_MOTIVES")}
        </Typography>
        <Box className={classes.contactBox} mb={2}>
          {blockList.map((block, index) => (
            <Typography key={block} variant="subtitle1">{`${
              index + 1
            } - ${block}`}</Typography>
          ))}
        </Box>

        <Typography variant="h6">{t("EMPLOYEE_STORE.CONTACT_INFO")}</Typography>
        <Box className={classes.contactBox} mb={2}>
          <Typography variant="subtitle1">
            {initialInfo?.emsCountryContactInfo}
          </Typography>
        </Box>
        <Typography variant="h6">
          {t("EMPLOYEE_STORE.BLOCK_DIALOG_SHOULD")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
