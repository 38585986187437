import { get, has } from "lodash";
import { es, enUS, fr, ptBR } from "date-fns/locale";
import { DATE_TIME_PATTERN } from "../../../util/Constants";
import { format, parse } from "date-fns";
import { utcStringDateToStringLocal } from "../../../util/UtilDates";

export const EMS_PRICE_FIELD = "price";
export const EMS_MIN_QUANTITY_FIELD = "quantity";

export const PAYMENT_SYSTEM_TRANSBANK_WEBPAY_PLUS = "transbank-webpay-plus";

export const EMS_PAYMENT_STATUS_INITIALIZED = "INITIALIZED";
export const EMS_PAYMENT_STATUS_AUTHORIZED = "AUTHORIZED";
export const EMS_PAYMENT_STATUS_REVERSED = "REVERSED";
export const EMS_PAYMENT_STATUS_FAILED = "FAILED";
export const EMS_PAYMENT_STATUS_NULLIFIED = "NULLIFIED";
export const EMS_PAYMENT_STATUS_PARTIALLY_NULLIFIED = "PARTIALLY_NULLIFIED";
export const EMS_PAYMENT_STATUS_CAPTURED = "CAPTURED";
export const EMS_PAYMENT_STATUS_ICON_MAP = {
  [EMS_PAYMENT_STATUS_INITIALIZED]: {
    icon: "fas fa-hourglass-half",
    color: "#f1c40f",
  },
  [EMS_PAYMENT_STATUS_AUTHORIZED]: {
    icon: "fas fa-check-circle",
    color: "#117864",
  },
};

export const EMS_DEFAULT_PAGE_NUMBER = 1;
export const EMS_DEFAULT_PAGE_SIZE = 25;

export const EMS_DEFAULT_SORT_BY = "price";
export const EMS_DEFAULT_SORT_DIR = "asc";

// Ems Filter Types
export const EMS_FILTER_CHECKBOX = "CheckBox";
export const EMS_FILTER_RANGE = "Range";
export const EMS_FILTER_SWITCH = "Switch";

export const EMS_FILTER_TYPES = {
  catalogo: "Catalogo",
  ean: "Ean",
  categoria: "Categoria",
  na: "No Aplica",
};

export const MAP_COUNTRY_CURRENCY = {
  CL: "CLP",
};

export const MAP_COUNTRY_LOCALE = {
  CL: "es-CL",
};

export const formatPriceWithCountry = ({ price, country }) => {
  const isCountryCurrencyConfigured = has(MAP_COUNTRY_CURRENCY, country);
  const isCountryLocaleConfigured = has(MAP_COUNTRY_LOCALE, country);

  if (isCountryCurrencyConfigured && isCountryLocaleConfigured) {
    return new Intl.NumberFormat(get(MAP_COUNTRY_LOCALE, country), {
      style: "currency",
      currency: get(MAP_COUNTRY_CURRENCY, country),
    }).format(price);
  } else {
    return price;
  }
};

// TRANSBANK STATUSES
export const TBK_TIMEOUT = "TBK_TIMEOUT";
export const TBK_CANCEL = "TBK_CANCEL";
export const TBK_ERROR = "TBK_ERROR";

export const INITIAL_VALIDATIONS_CHECKOUTS_RES_STATUSES = {
  [TBK_TIMEOUT]: "EMPLOYEE_STORE.INITIAL_CHECKOUT_VALIDATIONS_TBK_TIMEOUT",
  [TBK_CANCEL]: "EMPLOYEE_STORE.INITIAL_CHECKOUT_VALIDATIONS_TBK_CANCEL",
  [TBK_ERROR]: "EMPLOYEE_STORE.INITIAL_CHECKOUT_VALIDATIONS_TBK_ERROR",
};

export const EMS_TBK_PAYMENT_TYPES = {
  VD: "Venta Débito",
  VN: "Venta Normal",
  VC: "Venta en cuotas",
  SI: "3 cuotas sin interés",
  S2: "2 cuotas sin interés",
  NC: "N Cuotas sin interés",
  VP: "Venta Prepago",
};

export const EMS_TBK_PAYMENTS_RESPONSE_CODES = {
  0: "Transacción aprobada",
  "-1": "Rechazo - Posible error en el ingreso de datos de la transacción",
  "-2": "Rechazo - Se produjo fallo al procesar la transacción, este mensaje de rechazo se encuentra relacionado a parámetros de la tarjeta y/o su cuenta asociada",
  "-3": "Rechazo - Error en Transacción",
  "-4": "Rechazo - Rechazada por parte del emisor",
  "-5": "Rechazo - Transacción con riesgo de posible fraude",
};

export const EMS_ORDER_STATUS_MAPPING = {
  E: "EMPLOYEE_STORE.ORDER_STATUS_ENVIADA",
  P: "EMPLOYEE_STORE.ORDER_STATUS_PROCESSED",
  C: "EMPLOYEE_STORE.ORDER_STATUS_CONFIRMED",
  F: "EMPLOYEE_STORE.ORDER_STATUS_FACTURED",
};

const LOCALE_MAP_DATE_HUMAN_READABLE = {
  es: es,
  en: enUS,
  fr: fr,
  pt: ptBR,
};

export const formatDateToHumanReadableWithLang = ({ dateString, lang }) => {
  try {
    const dateStringInLocal = utcStringDateToStringLocal(
      dateString,
      DATE_TIME_PATTERN
    );
    const locale = LOCALE_MAP_DATE_HUMAN_READABLE[lang] || es;
    const date = parse(dateStringInLocal, DATE_TIME_PATTERN, new Date());
    const res = format(date, "d 'de' MMMM 'de' yyyy (HH:mm:ss)", { locale });
    return res;
  } catch (_) {
    return dateString;
  }
};
