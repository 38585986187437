import React from "react";
import { Box, Select, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useProducts from "../store/hooks/useProducts";
import { Skeleton } from "@material-ui/lab";
import useFilters from "../store/hooks/useFilters";
import usePagination from "../store/hooks/usePagination";
import { useTranslation } from "react-i18next";
import { toNumber } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "6px",
      alignItems: "flex-start",
      marginTop: "-6px",
    },
  },
  containerSelectBox: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  selectLabel: {
    fontWeight: 700,
  },
  select: {
    padding: 8,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  selectRoot: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default function TopBar() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loadingProducts } = useProducts();
  const { handleSorting, sortBy, sortDir, loadingFilters } = useFilters();
  const { totalItems, resultsCounter, page, totalPages } = usePagination();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const handleSortChange = (event) => {
    const [sortBy, sortDir] = event.target.value.split("_");
    handleSorting(sortBy, sortDir);
  };

  const getCurrentSortValue = () => {
    if (!sortBy || !sortDir) return "price_asc";
    return `${sortBy}_${sortDir}`;
  };

  const loadingItems = loadingProducts || loadingFilters;

  return (
    <Box className={classes.root}>
      {loadingItems ? (
        <Skeleton variant="text" width={"100%"} />
      ) : (
        <span>
          {`${t("PAGINATION_PAGE")} ${toNumber(page)} ${t(
            "PAGINATION_OF"
          )} ${totalPages} - `}
          {t("EMPLOYEE_STORE.SHOWING_RESULTS_COUNT", {
            sizeProducts: resultsCounter,
            totalItems,
          })}
        </span>
      )}
      {loadingItems ? null : (
        <Box className={classes.containerSelectBox}>
          <span className={classes.selectLabel}>
            {t("EMPLOYEE_STORE.ORDER_BY_TITLE")}{" "}
          </span>
          <Select
            value={getCurrentSortValue()}
            variant="outlined"
            size="small"
            classes={{ select: classes.select }}
            native
            disableUnderline
            onChange={handleSortChange}
            fullWidth={isXsDown}
          >
            <option value="price_asc">
              {t("EMPLOYEE_STORE.ORDER_BY_OPTION_PRICE_ASC")}
            </option>
            <option value="price_desc">
              {t("EMPLOYEE_STORE.ORDER_BY_OPTION_PRICE_DESC")}
            </option>
            <option value="name_asc">
              {t("EMPLOYEE_STORE.ORDER_BY_OPTION_NAME_ASC")}
            </option>
            <option value="name_desc">
              {t("EMPLOYEE_STORE.ORDER_BY_OPTION_NAME_DESC")}
            </option>
          </Select>
        </Box>
      )}
    </Box>
  );
}
