import { useCallback, useContext } from "react";
import useEmployeeStoreState from "../EmployeeStoreState";

import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { cancelOrderApi, createTransactionApi } from "../../api/paymentsApi";

const useCheckout = () => {
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const {
    validatingPayment,
    handleCommitPaymentTransaction,
    paymentStatus,
    paymentMessage,
    paymentSystemResponse,
    itemsDescription,
    checkoutStep,
    handleBackCheckoutStep,
    handleCompleteCheckout,
    setValidatingPayment,
    resetCheckoutStep,
  } = useEmployeeStoreState((state) => ({
    validatingPayment: state.validatingPayment,
    handleCommitPaymentTransaction: state.handleCommitPaymentTransaction,
    paymentStatus: state.paymentStatus,
    paymentMessage: state.paymentMessage,
    paymentSystemResponse: state.paymentSystemResponse,
    itemsDescription: state.itemsDescription,
    checkoutStep: state.checkoutStep,
    handleBackCheckoutStep: state.handleBackCheckoutStep,
    handleCompleteCheckout: state.handleCompleteCheckout,
    setValidatingPayment: state.setValidatingPayment,
    resetCheckoutStep: state.resetCheckoutStep,
  }));

  const commitTransaction = useCallback(
    async ({ body, paymentSystem }) => {
      const response = await handleCommitPaymentTransaction({
        headers: REQUEST_HEADERS,
        logout,
        body,
        paymentSystem,
      });

      return response;
    },
    [REQUEST_HEADERS, logout, handleCommitPaymentTransaction]
  );

  const createPayment = useCallback(
    async ({ paymentSystem, body }) => {
      setValidatingPayment(true);
      const response = await createTransactionApi({
        headers: REQUEST_HEADERS,
        logout,
        paymentSystem,
        body,
      });
      setValidatingPayment(false);
      return response;
    },
    [REQUEST_HEADERS, logout, setValidatingPayment]
  );

  const cancelOrder = useCallback(
    async ({ emsCompraId }) => {
      const response = await cancelOrderApi({
        headers: REQUEST_HEADERS,
        logout,
        emsCompraId,
      });

      return response;
    },
    [REQUEST_HEADERS, logout]
  );

  return {
    validatingPayment,
    commitTransaction,
    paymentStatus,
    paymentMessage,
    paymentSystemResponse,
    itemsDescription,
    checkoutStep,
    handleBackCheckoutStep,
    handleCompleteCheckout,
    createPayment,
    resetCheckoutStep,
    cancelOrder,
  };
};

export default useCheckout;
