import React, { useEffect } from "react";
import {
  Badge,
  CircularProgress,
  Icon,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  EMPLOYEE_STORE_PREFIX_ROUTE,
  EMPLOYEE_STORE_SHOPPING_CHECKOUT_ROUTE,
  EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE,
} from "../../../routes/EmployeeStoreRouter";
import { size } from "lodash";
import useFavorites from "../../../store/hooks/useFavorites";

export default function EmployeeStoreFavsButton() {
  const { t } = useTranslation();
  let history = useHistory();
  const { favorites, getFavorites, loadingFavorites } = useFavorites();

  const location = useLocation();
  const isFavPage =
    location.pathname ===
    `${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE}`;

  const isCheckoutPage =
    location.pathname ===
    `${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_SHOPPING_CHECKOUT_ROUTE}`;

  const sizeFavs = size(favorites);

  useEffect(() => {
    getFavorites();
  }, [getFavorites]);

  if (loadingFavorites && !isFavPage && !isCheckoutPage) {
    return <CircularProgress size={18} />;
  }

  if (loadingFavorites && isFavPage && isCheckoutPage) {
    return null;
  }

  return (
    <Tooltip title={t("EMPLOYEE_STORE.HEADER_BTN_FAVS")}>
      <IconButton
        aria-label="favorites-button"
        onClick={() => history.push(EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE)}
      >
        <Badge badgeContent={sizeFavs || 0} color="primary" showZero={false}>
          <Icon className="fas fa-heart" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
