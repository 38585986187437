import React, { useState } from "react";
import {
  makeStyles,
  Popper,
  Typography,
  ClickAwayListener,
} from "@material-ui/core";
import { Box, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useInfo from "../../../store/hooks/useInfo";

const useStyles = makeStyles(() => ({
  popperContainer: {
    zIndex: 10,
    width: 250,
    maxWidth: 400,
    height: 150,
    display: "flex",
    padding: 10,
    margin: 10,
    flexDirection: "column",
    borderRadius: 5,
    alignItems: "center",
    backgroundColor: "#ffffff",
    boxShadow: "rgb(0 0 0 / 20%) 5px 2px 10px",
  },
  contactInfo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    overflow: "hidden",
    wordBreak: "break-word",
    textAlign: "center",
  },
  profileInfo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflow: "hidden",
    padding: 10,
  },
  title: {
    color: "#3c7ee0",
  },
}));

export default function EmployeeStoreContactUs() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openContactPop, setOpenContactPop] = useState(false);
  const { t } = useTranslation();
  const { initialInfo, loadingInfo } = useInfo();

  const contactInfo = initialInfo?.emsCountryContactInfo;

  const handleBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenContactPop((prev) => !prev);
  };

  if (loadingInfo) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenContactPop(false)}>
      <div>
        <Link onClick={handleBtnClick} component="button" variant="body2">
          {t("EMPLOYEE_STORE.CONTACT_US")}
        </Link>

        <Popper
          open={openContactPop}
          anchorEl={anchorEl}
          transition
          className={classes.popperContainer}
        >
          <Typography variant="h5" className={classes.title}>
            {t("EMPLOYEE_STORE.CONTACT_INFO")}
          </Typography>
          <Box margin={0.5} className={classes.profileInfo}>
            <Typography variant="body2" className={classes.contactInfo}>
              {contactInfo}
            </Typography>
          </Box>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
