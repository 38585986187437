import create from "zustand";
import { persist } from "zustand/middleware";

const usePersistentStore = create(
  persist(
    (set, get) => ({
      throwToast: false,
      counterToast: 0,
      setThrowToast: (value) => {
        if (value === true) {
          set({ throwToast: true, counterToast: 2 });
        } else {
          const actualCounter = get().counterToast;
          if (actualCounter === 2) {
            set({ throwToast: true, counterToast: 1 });
          } else if (actualCounter === 1) {
            set({ throwToast: true, counterToast: 0 });
          } else {
            set({ throwToast: false });
          }
        }
      },
      showedChristmasAnimation: false,
      disableChristmasAnimation: () => {
        set({ showedChristmasAnimation: true });
      },
    }),
    {
      name: "persistent-storage",
      getStorage: () => localStorage,
    }
  )
);

export default usePersistentStore;
