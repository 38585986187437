import {
  Box,
  Icon,
  IconButton,
  makeStyles,
  Popover,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { has } from "lodash";
import React, { useState } from "react";
import { EMS_ORDER_STATUS_MAPPING } from "../../util/util-ems";
import { useTranslation } from "react-i18next";
import { safeFormattingDate } from "../../../../util/UtilDates";
import {
  DATE_TIME_PATTERN,
  FRONT_DATE_TIME_PATTERN,
} from "../../../../util/Constants";
import { getHumanReadableTimeOffset } from "../../../../core/hooks/useLiveClock";

const useStyles = makeStyles(() => ({
  boxInfo: {
    padding: 12,
    display: "flex",
    flexDirection: "column",
  },
  stepper: {
    padding: 12,
  },
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 2,
};

export default function HistorySapOrderStatusStepper({
  status,
  sapOrderId,
  emsOrderId,
  dateModification,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const timeOffset = getHumanReadableTimeOffset();

  if (!has(EMS_ORDER_STATUS_MAPPING, status)) {
    return null;
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <Icon
          className="fas fa-info-circle"
          style={{ ...commonIconStyles, color: "#55789a" }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box className={classes.boxInfo}>
          <Typography variant="subtitle1">
            <strong>
              {t("EMPLOYEE_STORE.HISTORY_ITEM_ORDERS_COL_ORDER_ID")}
            </strong>
            {` ${sapOrderId}`}
          </Typography>
          <Typography variant="subtitle1">
            <strong>
              {t("EMPLOYEE_STORE.HISTORY_ITEM_ORDERS_COL_EMS_ORDER_ID")}
            </strong>
            {` ${emsOrderId}`}
          </Typography>
          <Typography variant="subtitle1">
            <strong>
              {t("EMPLOYEE_STORE.HISTORY_ITEM_ORDERS_COL_ORDER_MOD_DATE")}
            </strong>

            {` ${safeFormattingDate(
              DATE_TIME_PATTERN,
              FRONT_DATE_TIME_PATTERN,
              dateModification
            )} (${timeOffset})`}
          </Typography>
        </Box>

        <Stepper
          activeStep={Object.keys(EMS_ORDER_STATUS_MAPPING).indexOf(status)}
          alternativeLabel
          className={classes.stepper}
        >
          {Object.entries(EMS_ORDER_STATUS_MAPPING).map(
            ([key, value], index) => {
              const isCompleted =
                index <= Object.keys(EMS_ORDER_STATUS_MAPPING).indexOf(status);
              return (
                <Step key={key} completed={isCompleted}>
                  <StepLabel
                    icon={
                      isCompleted ? (
                        <Icon
                          className="fas fa-check-circle"
                          style={{ ...commonIconStyles, color: "#17a589" }}
                        />
                      ) : (
                        <Icon
                          className="fas fa-clock"
                          style={{ ...commonIconStyles, color: "orange" }}
                        />
                      )
                    }
                  >
                    {t(value)}
                  </StepLabel>
                </Step>
              );
            }
          )}
        </Stepper>
      </Popover>
    </>
  );
}
