import React from "react";
import { Icon, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EMPLOYEE_STORE_HOME_ROUTE } from "../../../routes/EmployeeStoreRouter";

const useStyles = makeStyles((theme) => ({
  homeBtn: {
    color: theme.palette.content.mainColor,
    marginLeft: "-12px",
  },
}));

export default function EmployeeStoreHomeButton() {
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();

  const handleClick = () => {
    history.push(EMPLOYEE_STORE_HOME_ROUTE);
  };

  return (
    <Tooltip title={t("TAB_HOME")}>
      <IconButton
        onClick={handleClick}
        aria-label="back-to-employee-store-home-button"
        className={classes.homeBtn}
      >
        <Icon className="fas fa-home" />
      </IconButton>
    </Tooltip>
  );
}
