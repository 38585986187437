import React, { useContext, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Grid,
  Button,
  Box,
  Icon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  Hidden,
  useMediaQuery,
} from "@material-ui/core";

import HistoryItemContainerDetails from "./HistoryItemContainerDetails";
import { get, has, isArray, isEmpty, isNil, size, toString } from "lodash";
import {
  EMS_ORDER_STATUS_MAPPING,
  EMS_PAYMENT_STATUS_ICON_MAP,
  formatDateToHumanReadableWithLang,
  formatPriceWithCountry,
} from "../../util/util-ems";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { useLocale } from "../../../../core/hooks/useLocale";
import useToggleableSections, {
  SectionTitleToggler,
} from "../../store/hooks/useToggleableSections";
import { useTranslation } from "react-i18next";
import { safeFormattingDate } from "../../../../util/UtilDates";
import {
  DATE_TIME_PATTERN,
  FRONT_DATE_TIME_PATTERN,
} from "../../../../util/Constants";
import { getHumanReadableTimeOffset } from "../../../../core/hooks/useLiveClock";
import HistorySapOrderStatusStepper from "./HistorySapOrderStatusStepper";
import SimulationRoundDisclaimer from "../SimulationRoundDisclaimer";

const useStyles = makeStyles((theme) => ({
  historyItemPaper: {
    marginBottom: theme.spacing(2),
  },
  historyItemHeader: {
    backgroundColor: `${theme.palette.content.mainColor}`,
    color: theme.palette.primary.contrastText,
    padding: "12px 48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderRadius: 8,
  },
  historyItemDetails: {
    padding: "12px 10%",
  },
  historyItemHeaderProperty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      gap: "6px",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  },
  showMoreInfoBtn: {},
  paymentInfoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingInline: 12,
    gap: 6,
  },
  paymentInfoBoxMember: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
  },
  ordersInfoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingInline: 12,
    gap: 6,
    overflow: "auto",
  },
  simulationsInfoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingInline: 12,
    gap: 6,
    overflow: "auto",
  },
  simulationsItemsBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 12,
    overflow: "auto",
  },
  boxStatusIndicator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
  },
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 2,
};

const SECTION_PRODUCTS = "section-products";
const SECTION_PAYMENT_INFO = "section-payment";
const SECTION_ORDERS = "section-orders";
const SECTION_SIMULATIONS = "section-simulations";

const INITIAL_SECTIONS_STATE = {
  [SECTION_PRODUCTS]: true,
  [SECTION_PAYMENT_INFO]: true,
  [SECTION_ORDERS]: process.env.NODE_ENV === "development" ? true : false,
  [SECTION_SIMULATIONS]: process.env.NODE_ENV === "development" ? true : false,
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#55789a",
    color: theme.palette.common.white,
    fontSize: 16,
    border: "1px solid grey",
  },
  body: {
    fontSize: 14,
    border: "1px solid grey",
  },
}))(TableCell);

const HistoryItemContainer = ({
  emsIdCompra,
  purchaseDate,
  quantityProducts,
  quantityUnits,
  products,
  totalAmount,
  paymentInfo,
  parentSimulationId,
  simulations,
  sapOrders,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { auth } = useContext(AuthContext);
  const [locale] = useLocale();
  const [showMoreInfo, setShowMoreInfo] = useState(
    process.env.NODE_ENV === "development" ? true : false
  );
  const muiTheme = useTheme();
  const isMdDown = useMediaQuery(muiTheme.breakpoints.down("md"));

  const { openSections, toggleSection } = useToggleableSections({
    initialState: INITIAL_SECTIONS_STATE,
  });

  const handleToggleShowMoreInfo = () => {
    setShowMoreInfo((prev) => !prev);
  };

  const hasPaymentInfo =
    !isNil(paymentInfo) &&
    !isNil(paymentInfo?.transactionToken) &&
    paymentInfo?.transactionToken !== "";

  const hasSimulations = !isNil(simulations) && !isEmpty(simulations);

  const hasSapOrders = !isNil(sapOrders) && !isEmpty(sapOrders);

  const timeOffset = getHumanReadableTimeOffset();

  const humanDate = formatDateToHumanReadableWithLang({
    dateString: purchaseDate,
    lang: locale,
  });
  const purchaseDateToShow = isMdDown
    ? toString(humanDate).substring(0, size(humanDate) - 11)
    : humanDate;

  return (
    <Paper className={classes.historyItemPaper}>
      <Grid
        container
        className={classes.historyItemHeader}
        justifyContent="space-between"
      >
        <Grid
          item
          className={classes.historyItemHeaderProperty}
          lg={2}
          md={2}
          sm={12}
          xs={12}
        >
          <Typography variant="h5">
            {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_PURCHASE", {
              emsIdCompra,
            })}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.historyItemHeaderProperty}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <Hidden smDown>
            <Typography variant="h6">
              {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_DATE")}
            </Typography>
          </Hidden>

          <Typography variant="h6">{purchaseDateToShow}</Typography>
        </Grid>
        <Grid
          item
          className={classes.historyItemHeaderProperty}
          lg={2}
          md={2}
          sm={12}
          xs={12}
        >
          <Typography variant="h6">
            {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_PRODUCTS")}
          </Typography>
          <Typography variant="h6">{quantityProducts}</Typography>
        </Grid>
        <Grid
          item
          className={classes.historyItemHeaderProperty}
          lg={2}
          md={2}
          sm={12}
          xs={12}
        >
          <Typography variant="h6">
            {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_UNITS")}
          </Typography>
          <Typography variant="h6">{quantityUnits}</Typography>
        </Grid>
        <Grid
          item
          className={classes.historyItemHeaderProperty}
          lg={2}
          md={2}
          sm={12}
          xs={12}
        >
          <Typography variant="h5">
            {formatPriceWithCountry({
              price: totalAmount,
              country: auth?.emsCountry,
            })}
          </Typography>
        </Grid>
      </Grid>
      {!isNil(products) && isArray(products) && !isEmpty(products) ? (
        <Paper className={classes.historyItemDetails}>
          <SectionTitleToggler
            id={SECTION_PRODUCTS}
            open={openSections?.[SECTION_PRODUCTS]}
            title={t("EMPLOYEE_STORE.HISTORY_ITEM_SECTION_PRODUCTS", {
              size: quantityProducts,
            })}
            toggleSection={toggleSection}
          />
          {openSections?.[SECTION_PRODUCTS]
            ? products.map((product) => (
                <HistoryItemContainerDetails key={product?.ean} {...product} />
              ))
            : null}

          {hasPaymentInfo || hasSimulations || hasSapOrders ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              width={"100%"}
              justifyContent={"center"}
            >
              <Button
                onClick={handleToggleShowMoreInfo}
                className={classes.showMoreInfoBtn}
                startIcon={
                  <>
                    {showMoreInfo ? (
                      <Icon
                        className={"fas fa-eye-slash"}
                        style={commonIconStyles}
                      />
                    ) : (
                      <Icon className={"fas fa-eye"} style={commonIconStyles} />
                    )}
                  </>
                }
              >
                {showMoreInfo
                  ? t("EMPLOYEE_STORE.HISTORY_ITEM_HIDE_MORE_INFO")
                  : t("EMPLOYEE_STORE.HISTORY_ITEM_SHOW_MORE_INFO")}
              </Button>
            </Box>
          ) : null}

          {showMoreInfo ? (
            <>
              {hasPaymentInfo && (
                <Box mb={2}>
                  <SectionTitleToggler
                    id={SECTION_PAYMENT_INFO}
                    open={openSections?.[SECTION_PAYMENT_INFO]}
                    title={t(
                      "EMPLOYEE_STORE.HISTORY_ITEM_SECTION_PAYMENT_IFNO"
                    )}
                    toggleSection={toggleSection}
                  />
                  {openSections?.[SECTION_PAYMENT_INFO] ? (
                    <Box className={classes.paymentInfoBox}>
                      <Box className={classes.paymentInfoBoxMember}>
                        <Icon
                          className="fas fa-clipboard-list"
                          style={commonIconStyles}
                        />
                        <Typography variant="subtitule1">
                          <strong>Status:</strong> {paymentInfo?.status}
                        </Typography>
                        {has(
                          EMS_PAYMENT_STATUS_ICON_MAP,
                          paymentInfo?.status
                        ) ? (
                          <Icon
                            className={
                              get(
                                EMS_PAYMENT_STATUS_ICON_MAP,
                                paymentInfo?.status
                              )?.icon
                            }
                            style={{
                              ...commonIconStyles,
                              color: get(
                                EMS_PAYMENT_STATUS_ICON_MAP,
                                paymentInfo?.status
                              )?.color,
                            }}
                          />
                        ) : (
                          <Icon
                            className="fas fa-times-circle"
                            style={{
                              ...commonIconStyles,
                              color: "#cb4335",
                            }}
                          />
                        )}
                      </Box>
                      <Box className={classes.paymentInfoBoxMember}>
                        <Icon
                          className="fas fa-clock"
                          style={commonIconStyles}
                        />
                        <Typography variant="subtitule1">
                          <strong>
                            {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_DATE")}:
                          </strong>{" "}
                          {safeFormattingDate(
                            DATE_TIME_PATTERN,
                            FRONT_DATE_TIME_PATTERN,
                            paymentInfo?.modificationDate
                          )}
                          ({timeOffset})
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              )}

              {hasSapOrders ? (
                <Box mb={2}>
                  <SectionTitleToggler
                    id={SECTION_ORDERS}
                    open={openSections?.[SECTION_ORDERS]}
                    title={t("EMPLOYEE_STORE.HISTORY_ITEM_SECTION_ORDERS")}
                    toggleSection={toggleSection}
                  />
                  {openSections?.[SECTION_ORDERS] ? (
                    <Box className={classes.ordersInfoBox}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              {t(
                                "EMPLOYEE_STORE.HISTORY_ITEM_ORDERS_COL_ORDER_ID"
                              )}
                            </StyledTableCell>
                            {/*                             <StyledTableCell align="center">
                              {t(
                                "EMPLOYEE_STORE.HISTORY_ITEM_ORDERS_COL_EMS_ORDER_ID"
                              )}
                            </StyledTableCell> */}
                            <StyledTableCell align="center">
                              {t(
                                "EMPLOYEE_STORE.HISTORY_ITEM_ORDERS_COL_ORDER_STATUS"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {t(
                                "EMPLOYEE_STORE.HISTORY_ITEM_ORDERS_COL_ORDER_MOD_DATE"
                              )}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sapOrders.map(
                            ({
                              sapOrderId,
                              emsOrderId,
                              sapStatus,
                              dateModification,
                            }) => (
                              <TableRow key={sapOrderId}>
                                <StyledTableCell align="center">
                                  {sapOrderId}
                                </StyledTableCell>
                                {/* <StyledTableCell align="center">
                                  {emsOrderId}
                                </StyledTableCell> */}
                                <StyledTableCell align="center">
                                  <Box className={classes.boxStatusIndicator}>
                                    <Typography>
                                      {has(EMS_ORDER_STATUS_MAPPING, sapStatus)
                                        ? t(
                                            get(
                                              EMS_ORDER_STATUS_MAPPING,
                                              sapStatus
                                            )
                                          )
                                        : "-"}
                                    </Typography>
                                    <HistorySapOrderStatusStepper
                                      status={sapStatus}
                                      sapOrderId={sapOrderId}
                                      emsOrderId={emsOrderId}
                                      dateModification={dateModification}
                                    />
                                  </Box>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {`${safeFormattingDate(
                                    DATE_TIME_PATTERN,
                                    FRONT_DATE_TIME_PATTERN,
                                    dateModification
                                  )} (${timeOffset})`}
                                </StyledTableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </Box>
                  ) : null}
                </Box>
              ) : null}

              {hasSimulations ? (
                <>
                  <SectionTitleToggler
                    id={SECTION_SIMULATIONS}
                    open={openSections?.[SECTION_SIMULATIONS]}
                    title={t("EMPLOYEE_STORE.HISTORY_ITEM_SECTION_SIMULATION")}
                    toggleSection={toggleSection}
                  />
                  {openSections?.[SECTION_SIMULATIONS] ? (
                    <Box className={classes.simulationsInfoBox}>
                      <Box className={classes.paymentInfoBoxMember}>
                        <Icon
                          className="fas fa-play-circle"
                          style={commonIconStyles}
                        />
                        <Typography variant="subtitule1">
                          <strong>Parent Simulation ID:</strong>{" "}
                          {parentSimulationId}
                        </Typography>
                      </Box>
                      <SimulationRoundDisclaimer />
                      <Box className={classes.simulationsItemsBox}>
                        {simulations.map((simulation, index) => {
                          if (
                            !isNil(simulation) &&
                            !isEmpty(simulation) &&
                            !isNil(simulation.items) &&
                            isArray(simulation.items) &&
                            !isEmpty(simulation.items)
                          ) {
                            const {
                              items,
                              simulationId,
                              salesOrg,
                              channel,
                              divisionCode,
                            } = simulation;
                            return (
                              <>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell align="center">
                                        {`#${
                                          index + 1
                                        } - ${simulationId} (${toString(
                                          salesOrg
                                        )}-${toString(channel)}-${toString(
                                          divisionCode
                                        )})`}
                                      </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell align="center">
                                        EAN
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        SKU
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {t(
                                          "EMPLOYEE_STORE.SIMULATION_QUANTITY"
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {t(
                                          "EMPLOYEE_STORE.SIMULATION_PRICE_UNIT"
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {t(
                                          "EMPLOYEE_STORE.SIMULATION_PRICE_TOTAL_UNITS"
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {t(
                                          "EMPLOYEE_STORE.SIMULATION_ITEM_TOTAL_DESC"
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {t("EMPLOYEE_STORE.SIMULATION_ITEM_CA")}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {t(
                                          "EMPLOYEE_STORE.SIMULATION_ITEM_TAXES"
                                        )}
                                      </StyledTableCell>

                                      <StyledTableCell align="center">
                                        {t(
                                          "EMPLOYEE_STORE.SIMULATION_TOTAL_PRICES_SIMU"
                                        )}
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {items.map(
                                      ({
                                        internationalArticleNumber,
                                        responseSkuNumber,
                                        orderQuantityBaseUnit,
                                        responseUnityPrice,
                                        responseItemPrice,
                                        responseIva,
                                        responseGrossValue,
                                        responseTotalDesc,
                                        responseTotalCaSimulation,
                                      }) => (
                                        <TableRow
                                          key={internationalArticleNumber}
                                        >
                                          <StyledTableCell align="center">
                                            {internationalArticleNumber}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            {responseSkuNumber}
                                          </StyledTableCell>

                                          <StyledTableCell align="center">
                                            {formatPriceWithCountry({
                                              price: responseUnityPrice,
                                              country: auth?.emsCountry,
                                            })}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            {orderQuantityBaseUnit}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            {formatPriceWithCountry({
                                              price: responseItemPrice,
                                              country: auth?.emsCountry,
                                            })}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            {formatPriceWithCountry({
                                              price: responseTotalDesc,
                                              country: auth?.emsCountry,
                                            })}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            {formatPriceWithCountry({
                                              price: responseTotalCaSimulation,
                                              country: auth?.emsCountry,
                                            })}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            {formatPriceWithCountry({
                                              price: responseIva,
                                              country: auth?.emsCountry,
                                            })}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            {formatPriceWithCountry({
                                              price: responseGrossValue,
                                              country: auth?.emsCountry,
                                            })}
                                          </StyledTableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Box>
                    </Box>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </Paper>
      ) : null}
    </Paper>
  );
};

export default HistoryItemContainer;
