import React, { useContext } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import { EMPLOYEE_STORE_HOME_ROUTE } from "../../../routes/EmployeeStoreRouter";
import { AuthContext } from "../../../../../core/providers/AuthContext";

const FLAG_SIZE = 16;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  logoUrlImg: {
    width: 200,
    cursor: "pointer",
    marginBottom: -11,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  logoUrlImgQa: {
    width: 75,
    marginBottom: 7,
    marginLeft: "-14px",
    marginRight: 6
  },
  flagContainer: {
    right: "-14px",
    width: "auto",
    border: "1px solid #bfb5b5",
    bottom: "-6px",
    height: "auto",
    display: "flex",
    padding: "6px",
    position: "absolute",
    alignItems: "center",
    borderRadius: "100%",
    justifyContent: "center",
    backgroundColor: "#f0efef",
  },
}));

export const flagIConStart = {
  width: FLAG_SIZE,
  height: FLAG_SIZE,
};

const SHOW_COUNTRY_FLAG = false;

const mainLogoUrl = `${process.env.PUBLIC_URL}/assets/img/employee-store/employee-store-logo.webp`;
const qaLogoUrl = `${process.env.PUBLIC_URL}/assets/img/LOREAL_light_${process.env.REACT_APP_ENV}.png`;

export default function EmployeeStoreLogo() {
  const classes = useStyles();
  let history = useHistory();
  const { auth } = useContext(AuthContext);

  const isQAEnv = process.env.REACT_APP_ENV === "QA";

  return (
    <div className={classes.container}>
      <img
        src={mainLogoUrl}
        alt="store-logo"
        className={classes.logoUrlImg}
        onClick={() => history.push(EMPLOYEE_STORE_HOME_ROUTE)}
      />
      {SHOW_COUNTRY_FLAG ? (
        <div className={classes.flagContainer}>
          <ReactCountryFlag
            svg
            countryCode={auth?.emsCountry}
            style={flagIConStart}
          />
        </div>
      ) : null}
      {isQAEnv ? (
        <img src={qaLogoUrl} className={classes.logoUrlImgQa} alt="qa-logo" />
      ) : null}
    </div>
  );
}
